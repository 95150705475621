@import "../../../../scss/variables.scss";

.section-2-root {
    font-size: 4vw;
    font-size: clamp(1.5rem, 4vw, 3.35rem);
}

.section-2-discord {
    width: 37px;
    max-width: 37px;
    margin-left: 5px;
}

.section-2-join-discord-text {
    font-size: 1.2rem;
    color: $light;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: $white;
    }
}