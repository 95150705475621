/* background-image */
/* black-layer */
.background-image {
	height: calc(100vh);
}
.black-layer {
	background: #000;
	opacity: 0.8;
	height: 100%;
	width: 100%;
	h1 {
		text-align: center;
		font-weight: 900;
		font-size: 32px;
		padding-top: 10px;
		font-family: 'Akira Expanded';
		font-weight: bold;
		font-style: normal;
		span {
			color: #fff;
			border-bottom: 1px solid #ff00ff;
		}
	}
	.front-image {
		width: 70%;
		margin: auto;
		margin-top: 20px;
		img {
			width: 100%;
		}
	}
}
@media only screen and (max-width: 600px) {
	.black-layer {
		.front-image {
			width: 70%;
			margin: auto;
			margin-top: 100px;
		}
	}
}

/* home fullscreen image */
.home-fs-img
{
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-size: cover;
	background-repeat: "no-repeat";
	justify-content: center;
	margin-bottom: 30px;
}

.home-text-img {
    padding: 20px;
    max-width: 1360px;
}