.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-lg {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-md {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-sm {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.container-xl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;
	>.col {
		padding-right: 0;
		padding-left: 0;
	}
	>[class*=col-] {
		padding-right: 0;
		padding-left: 0;
	}
}
.col {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
}
.col-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}
.col-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}
.col-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}
.col-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}
.col-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}
.col-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}
.col-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}
.col-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}
.col-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}
.col-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}
.col-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}
.col-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}
.col-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}
.col-lg {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-lg-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-md-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-sm-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-1 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-10 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-11 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-12 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-6 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-7 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-8 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-9 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.row-cols-1 {
	>* {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.row-cols-2 {
	>* {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
.row-cols-3 {
	>* {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
.row-cols-4 {
	>* {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
}
.row-cols-5 {
	>* {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
}
.row-cols-6 {
	>* {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
}
.order-first {
	-ms-flex-order: -1;
	order: -1;
}
.order-last {
	-ms-flex-order: 13;
	order: 13;
}
.order-0 {
	-ms-flex-order: 0;
	order: 0;
}
.order-1 {
	-ms-flex-order: 1;
	order: 1;
}
.order-2 {
	-ms-flex-order: 2;
	order: 2;
}
.order-3 {
	-ms-flex-order: 3;
	order: 3;
}
.order-4 {
	-ms-flex-order: 4;
	order: 4;
}
.order-5 {
	-ms-flex-order: 5;
	order: 5;
}
.order-6 {
	-ms-flex-order: 6;
	order: 6;
}
.order-7 {
	-ms-flex-order: 7;
	order: 7;
}
.order-8 {
	-ms-flex-order: 8;
	order: 8;
}
.order-9 {
	-ms-flex-order: 9;
	order: 9;
}
.order-10 {
	-ms-flex-order: 10;
	order: 10;
}
.order-11 {
	-ms-flex-order: 11;
	order: 11;
}
.order-12 {
	-ms-flex-order: 12;
	order: 12;
}
.offset-1 {
	margin-left: 8.333333%;
}
.offset-2 {
	margin-left: 16.666667%;
}
.offset-3 {
	margin-left: 25%;
}
.offset-4 {
	margin-left: 33.333333%;
}
.offset-5 {
	margin-left: 41.666667%;
}
.offset-6 {
	margin-left: 50%;
}
.offset-7 {
	margin-left: 58.333333%;
}
.offset-8 {
	margin-left: 66.666667%;
}
.offset-9 {
	margin-left: 75%;
}
.offset-10 {
	margin-left: 83.333333%;
}
.offset-11 {
	margin-left: 91.666667%;
}

@media (min-width:576px) {
	.container {
		max-width: 540px;
		max-width: 540px;
	}
	.container-sm {
		max-width: 540px;
	}
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
	}
	.row-cols-sm-1 {
		>* {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-sm-2 {
		>* {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-sm-3 {
		>* {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	}
	.row-cols-sm-4 {
		>* {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-sm-5 {
		>* {
			-ms-flex: 0 0 20%;
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-sm-6 {
		>* {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
	}
	.col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-sm-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-sm-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-sm-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-sm-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-sm-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-sm-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-sm-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-sm-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-sm-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-sm-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-sm-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-sm-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-sm-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-sm-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-sm-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-sm-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.333333%;
	}
	.offset-sm-2 {
		margin-left: 16.666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.333333%;
	}
	.offset-sm-5 {
		margin-left: 41.666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.333333%;
	}
	.offset-sm-8 {
		margin-left: 66.666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.333333%;
	}
	.offset-sm-11 {
		margin-left: 91.666667%;
	}
}

@media (min-width:768px) {
	.container {
		max-width: 720px;
		max-width: 720px;
	}
	.container-md {
		max-width: 720px;
	}
	.container-sm {
		max-width: 720px;
	}
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
	}
	.row-cols-md-1 {
		>* {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-md-2 {
		>* {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-md-3 {
		>* {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	}
	.row-cols-md-4 {
		>* {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-md-5 {
		>* {
			-ms-flex: 0 0 20%;
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-md-6 {
		>* {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
	}
	.col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-md-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-md-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-md-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-md-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-md-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-md-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-md-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-md-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-md-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-md-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-md-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-md-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-md-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-md-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-md-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-md-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-md-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.333333%;
	}
	.offset-md-2 {
		margin-left: 16.666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.333333%;
	}
	.offset-md-5 {
		margin-left: 41.666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.333333%;
	}
	.offset-md-8 {
		margin-left: 66.666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.333333%;
	}
	.offset-md-11 {
		margin-left: 91.666667%;
	}
	.dropdown-menu-md-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-md-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-md {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
		.navbar-nav {
			-ms-flex-direction: row;
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: .5rem;
				padding-left: .5rem;
			}
		}
		>.container {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-fluid {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-lg {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-md {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-sm {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-xl {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: -ms-flexbox !important;
			display: flex !important;
			-ms-flex-preferred-size: auto;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-md {
		-ms-flex-direction: row;
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: .25rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: .25rem;
				border-bottom-left-radius: 0;
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
		>.list-group-item+.list-group-item {
			border-top-width: 1px;
			border-left-width: 0;
		}
		>.list-group-item+.list-group-item.active {
			margin-left: -1px;
			border-left-width: 1px;
		}
	}
	.d-md-none {
		display: none !important;
	}
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-md-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-md-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-md-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-md-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-md-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-md-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-md-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-md-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-md-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-md-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-md-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-md-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-md-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-md-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-md-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-md-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-md-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-md-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-md-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-md-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-md-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.float-md-left {
		float: left !important;
	}
	.float-md-right {
		float: right !important;
	}
	.float-md-none {
		float: none !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-md-0 {
		margin-right: 0 !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.ml-md-0 {
		margin-left: 0 !important;
	}
	.m-md-1 {
		margin: .25rem !important;
	}
	.mt-md-1 {
		margin-top: .25rem !important;
	}
	.my-md-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-md-1 {
		margin-right: .25rem !important;
	}
	.mx-md-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-md-1 {
		margin-bottom: .25rem !important;
	}
	.ml-md-1 {
		margin-left: .25rem !important;
	}
	.m-md-2 {
		margin: .5rem !important;
	}
	.mt-md-2 {
		margin-top: .5rem !important;
	}
	.my-md-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-md-2 {
		margin-right: .5rem !important;
	}
	.mx-md-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-md-2 {
		margin-bottom: .5rem !important;
	}
	.ml-md-2 {
		margin-left: .5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-md-3 {
		margin-right: 1rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.ml-md-3 {
		margin-left: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-md-4 {
		margin-right: 1.5rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-md-4 {
		margin-left: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-md-5 {
		margin-right: 3rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.ml-md-5 {
		margin-left: 3rem !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-md-0 {
		padding-right: 0 !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pl-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: .25rem !important;
	}
	.pt-md-1 {
		padding-top: .25rem !important;
	}
	.py-md-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-md-1 {
		padding-right: .25rem !important;
	}
	.px-md-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-md-1 {
		padding-bottom: .25rem !important;
	}
	.pl-md-1 {
		padding-left: .25rem !important;
	}
	.p-md-2 {
		padding: .5rem !important;
	}
	.pt-md-2 {
		padding-top: .5rem !important;
	}
	.py-md-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-md-2 {
		padding-right: .5rem !important;
	}
	.px-md-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-md-2 {
		padding-bottom: .5rem !important;
	}
	.pl-md-2 {
		padding-left: .5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-md-3 {
		padding-right: 1rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pl-md-3 {
		padding-left: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-md-4 {
		padding-right: 1.5rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-md-4 {
		padding-left: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-md-5 {
		padding-right: 3rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.pl-md-5 {
		padding-left: 3rem !important;
	}
	.m-md-n1 {
		margin: -.25rem !important;
	}
	.mt-md-n1 {
		margin-top: -.25rem !important;
	}
	.my-md-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-md-n1 {
		margin-right: -.25rem !important;
	}
	.mx-md-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-md-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-md-n1 {
		margin-left: -.25rem !important;
	}
	.m-md-n2 {
		margin: -.5rem !important;
	}
	.mt-md-n2 {
		margin-top: -.5rem !important;
	}
	.my-md-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-md-n2 {
		margin-right: -.5rem !important;
	}
	.mx-md-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-md-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-md-n2 {
		margin-left: -.5rem !important;
	}
	.m-md-n3 {
		margin: -1rem !important;
	}
	.mt-md-n3 {
		margin-top: -1rem !important;
	}
	.my-md-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-md-n3 {
		margin-right: -1rem !important;
	}
	.mx-md-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-md-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-md-n3 {
		margin-left: -1rem !important;
	}
	.m-md-n4 {
		margin: -1.5rem !important;
	}
	.mt-md-n4 {
		margin-top: -1.5rem !important;
	}
	.my-md-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-md-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-md-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-md-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-md-n4 {
		margin-left: -1.5rem !important;
	}
	.m-md-n5 {
		margin: -3rem !important;
	}
	.mt-md-n5 {
		margin-top: -3rem !important;
	}
	.my-md-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-md-n5 {
		margin-right: -3rem !important;
	}
	.mx-md-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-md-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-md-n5 {
		margin-left: -3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-md-auto {
		margin-right: auto !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ml-md-auto {
		margin-left: auto !important;
	}
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}
@media (min-width:992px) {
	.container {
		max-width: 960px;
		max-width: 960px;
	}
	.container-lg {
		max-width: 960px;
	}
	.container-md {
		max-width: 960px;
	}
	.container-sm {
		max-width: 960px;
	}
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
	}
	.row-cols-lg-1 {
		>* {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-lg-2 {
		>* {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-lg-3 {
		>* {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	}
	.row-cols-lg-4 {
		>* {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-lg-5 {
		>* {
			-ms-flex: 0 0 20%;
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-lg-6 {
		>* {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
	}
	.col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-lg-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-lg-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-lg-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-lg-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-lg-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-lg-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-lg-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-lg-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-lg-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-lg-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-lg-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.333333%;
	}
	.offset-lg-2 {
		margin-left: 16.666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.333333%;
	}
	.offset-lg-5 {
		margin-left: 41.666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.333333%;
	}
	.offset-lg-8 {
		margin-left: 66.666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.333333%;
	}
	.offset-lg-11 {
		margin-left: 91.666667%;
	}
	.dropdown-menu-lg-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-lg-right {
		right: 0;
		left: auto;
	}
	.list-group-horizontal-lg {
		-ms-flex-direction: row;
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: .25rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: .25rem;
				border-bottom-left-radius: 0;
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
		>.list-group-item+.list-group-item {
			border-top-width: 1px;
			border-left-width: 0;
		}
		>.list-group-item+.list-group-item.active {
			margin-left: -1px;
			border-left-width: 1px;
		}
	}
	.modal-lg {
		max-width: 800px;
	}
	.modal-xl {
		max-width: 800px;
	}
	.d-lg-none {
		display: none !important;
	}
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-lg-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-lg-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-lg-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-lg-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-lg-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-lg-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-lg-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-lg-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-lg-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-lg-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-lg-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-lg-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-lg-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-lg-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.float-lg-left {
		float: left !important;
	}
	.float-lg-right {
		float: right !important;
	}
	.float-lg-none {
		float: none !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-lg-0 {
		margin-right: 0 !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.ml-lg-0 {
		margin-left: 0 !important;
	}
	.m-lg-1 {
		margin: .25rem !important;
	}
	.mt-lg-1 {
		margin-top: .25rem !important;
	}
	.my-lg-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-lg-1 {
		margin-right: .25rem !important;
	}
	.mx-lg-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-lg-1 {
		margin-bottom: .25rem !important;
	}
	.ml-lg-1 {
		margin-left: .25rem !important;
	}
	.m-lg-2 {
		margin: .5rem !important;
	}
	.mt-lg-2 {
		margin-top: .5rem !important;
	}
	.my-lg-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-lg-2 {
		margin-right: .5rem !important;
	}
	.mx-lg-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-lg-2 {
		margin-bottom: .5rem !important;
	}
	.ml-lg-2 {
		margin-left: .5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-lg-3 {
		margin-right: 1rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.ml-lg-3 {
		margin-left: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-lg-4 {
		margin-right: 1.5rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-lg-4 {
		margin-left: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-lg-5 {
		margin-right: 3rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.ml-lg-5 {
		margin-left: 3rem !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-lg-0 {
		padding-right: 0 !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pl-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: .25rem !important;
	}
	.pt-lg-1 {
		padding-top: .25rem !important;
	}
	.py-lg-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-lg-1 {
		padding-right: .25rem !important;
	}
	.px-lg-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-lg-1 {
		padding-bottom: .25rem !important;
	}
	.pl-lg-1 {
		padding-left: .25rem !important;
	}
	.p-lg-2 {
		padding: .5rem !important;
	}
	.pt-lg-2 {
		padding-top: .5rem !important;
	}
	.py-lg-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-lg-2 {
		padding-right: .5rem !important;
	}
	.px-lg-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-lg-2 {
		padding-bottom: .5rem !important;
	}
	.pl-lg-2 {
		padding-left: .5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-lg-3 {
		padding-right: 1rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pl-lg-3 {
		padding-left: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-lg-4 {
		padding-right: 1.5rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-lg-4 {
		padding-left: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-lg-5 {
		padding-right: 3rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.pl-lg-5 {
		padding-left: 3rem !important;
	}
	.m-lg-n1 {
		margin: -.25rem !important;
	}
	.mt-lg-n1 {
		margin-top: -.25rem !important;
	}
	.my-lg-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-lg-n1 {
		margin-right: -.25rem !important;
	}
	.mx-lg-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-lg-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-lg-n1 {
		margin-left: -.25rem !important;
	}
	.m-lg-n2 {
		margin: -.5rem !important;
	}
	.mt-lg-n2 {
		margin-top: -.5rem !important;
	}
	.my-lg-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-lg-n2 {
		margin-right: -.5rem !important;
	}
	.mx-lg-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-lg-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-lg-n2 {
		margin-left: -.5rem !important;
	}
	.m-lg-n3 {
		margin: -1rem !important;
	}
	.mt-lg-n3 {
		margin-top: -1rem !important;
	}
	.my-lg-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-lg-n3 {
		margin-right: -1rem !important;
	}
	.mx-lg-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-lg-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-lg-n3 {
		margin-left: -1rem !important;
	}
	.m-lg-n4 {
		margin: -1.5rem !important;
	}
	.mt-lg-n4 {
		margin-top: -1.5rem !important;
	}
	.my-lg-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-lg-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-lg-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-lg-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-lg-n4 {
		margin-left: -1.5rem !important;
	}
	.m-lg-n5 {
		margin: -3rem !important;
	}
	.mt-lg-n5 {
		margin-top: -3rem !important;
	}
	.my-lg-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-lg-n5 {
		margin-right: -3rem !important;
	}
	.mx-lg-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-lg-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-lg-n5 {
		margin-left: -3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-lg-auto {
		margin-right: auto !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ml-lg-auto {
		margin-left: auto !important;
	}
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}
@media (min-width:1200px) {
	.container {
		max-width: 1140px;
		max-width: 1140px;
	}
	.container-lg {
		max-width: 1140px;
	}
	.container-md {
		max-width: 1140px;
	}
	.container-sm {
		max-width: 1140px;
	}
	.container-xl {
		max-width: 1140px;
	}
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		min-width: 0;
		max-width: 100%;
	}
	.row-cols-xl-1 {
		>* {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.row-cols-xl-2 {
		>* {
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.row-cols-xl-3 {
		>* {
			-ms-flex: 0 0 33.333333%;
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	}
	.row-cols-xl-4 {
		>* {
			-ms-flex: 0 0 25%;
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
	.row-cols-xl-5 {
		>* {
			-ms-flex: 0 0 20%;
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	.row-cols-xl-6 {
		>* {
			-ms-flex: 0 0 16.666667%;
			flex: 0 0 16.666667%;
			max-width: 16.666667%;
		}
	}
	.col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-xl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-xl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		-ms-flex-order: -1;
		order: -1;
	}
	.order-xl-last {
		-ms-flex-order: 13;
		order: 13;
	}
	.order-xl-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-xl-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-xl-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-xl-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-xl-6 {
		-ms-flex-order: 6;
		order: 6;
	}
	.order-xl-7 {
		-ms-flex-order: 7;
		order: 7;
	}
	.order-xl-8 {
		-ms-flex-order: 8;
		order: 8;
	}
	.order-xl-9 {
		-ms-flex-order: 9;
		order: 9;
	}
	.order-xl-10 {
		-ms-flex-order: 10;
		order: 10;
	}
	.order-xl-11 {
		-ms-flex-order: 11;
		order: 11;
	}
	.order-xl-12 {
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.333333%;
	}
	.offset-xl-2 {
		margin-left: 16.666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.333333%;
	}
	.offset-xl-5 {
		margin-left: 41.666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.333333%;
	}
	.offset-xl-8 {
		margin-left: 66.666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.333333%;
	}
	.offset-xl-11 {
		margin-left: 91.666667%;
	}
	.dropdown-menu-xl-left {
		right: auto;
		left: 0;
	}
	.dropdown-menu-xl-right {
		right: 0;
		left: auto;
	}
	.navbar-expand-xl {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
		.navbar-nav {
			-ms-flex-direction: row;
			flex-direction: row;
			.dropdown-menu {
				position: absolute;
			}
			.nav-link {
				padding-right: .5rem;
				padding-left: .5rem;
			}
		}
		>.container {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-fluid {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-lg {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-md {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-sm {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		>.container-xl {
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
		}
		.navbar-collapse {
			display: -ms-flexbox !important;
			display: flex !important;
			-ms-flex-preferred-size: auto;
			flex-basis: auto;
		}
		.navbar-toggler {
			display: none;
		}
	}
	.list-group-horizontal-xl {
		-ms-flex-direction: row;
		flex-direction: row;
		>.list-group-item {
			&:first-child {
				border-bottom-left-radius: .25rem;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-top-right-radius: .25rem;
				border-bottom-left-radius: 0;
			}
		}
		>.list-group-item.active {
			margin-top: 0;
		}
		>.list-group-item+.list-group-item {
			border-top-width: 1px;
			border-left-width: 0;
		}
		>.list-group-item+.list-group-item.active {
			margin-left: -1px;
			border-left-width: 1px;
		}
	}
	.modal-xl {
		max-width: 1140px;
	}
	.d-xl-none {
		display: none !important;
	}
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
	.flex-xl-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}
	.flex-xl-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}
	.flex-xl-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}
	.flex-xl-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}
	.flex-xl-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}
	.justify-content-xl-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}
	.justify-content-xl-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}
	.align-items-xl-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}
	.align-items-xl-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}
	.align-content-xl-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}
	.align-content-xl-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}
	.align-content-xl-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}
	.align-self-xl-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}
	.align-self-xl-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
	.float-xl-left {
		float: left !important;
	}
	.float-xl-right {
		float: right !important;
	}
	.float-xl-none {
		float: none !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.mr-xl-0 {
		margin-right: 0 !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.ml-xl-0 {
		margin-left: 0 !important;
	}
	.m-xl-1 {
		margin: .25rem !important;
	}
	.mt-xl-1 {
		margin-top: .25rem !important;
	}
	.my-xl-1 {
		margin-top: .25rem !important;
		margin-bottom: .25rem !important;
	}
	.mr-xl-1 {
		margin-right: .25rem !important;
	}
	.mx-xl-1 {
		margin-right: .25rem !important;
		margin-left: .25rem !important;
	}
	.mb-xl-1 {
		margin-bottom: .25rem !important;
	}
	.ml-xl-1 {
		margin-left: .25rem !important;
	}
	.m-xl-2 {
		margin: .5rem !important;
	}
	.mt-xl-2 {
		margin-top: .5rem !important;
	}
	.my-xl-2 {
		margin-top: .5rem !important;
		margin-bottom: .5rem !important;
	}
	.mr-xl-2 {
		margin-right: .5rem !important;
	}
	.mx-xl-2 {
		margin-right: .5rem !important;
		margin-left: .5rem !important;
	}
	.mb-xl-2 {
		margin-bottom: .5rem !important;
	}
	.ml-xl-2 {
		margin-left: .5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.mr-xl-3 {
		margin-right: 1rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.ml-xl-3 {
		margin-left: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.mr-xl-4 {
		margin-right: 1.5rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.ml-xl-4 {
		margin-left: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.mr-xl-5 {
		margin-right: 3rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.ml-xl-5 {
		margin-left: 3rem !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.pr-xl-0 {
		padding-right: 0 !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pl-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: .25rem !important;
	}
	.pt-xl-1 {
		padding-top: .25rem !important;
	}
	.py-xl-1 {
		padding-top: .25rem !important;
		padding-bottom: .25rem !important;
	}
	.pr-xl-1 {
		padding-right: .25rem !important;
	}
	.px-xl-1 {
		padding-right: .25rem !important;
		padding-left: .25rem !important;
	}
	.pb-xl-1 {
		padding-bottom: .25rem !important;
	}
	.pl-xl-1 {
		padding-left: .25rem !important;
	}
	.p-xl-2 {
		padding: .5rem !important;
	}
	.pt-xl-2 {
		padding-top: .5rem !important;
	}
	.py-xl-2 {
		padding-top: .5rem !important;
		padding-bottom: .5rem !important;
	}
	.pr-xl-2 {
		padding-right: .5rem !important;
	}
	.px-xl-2 {
		padding-right: .5rem !important;
		padding-left: .5rem !important;
	}
	.pb-xl-2 {
		padding-bottom: .5rem !important;
	}
	.pl-xl-2 {
		padding-left: .5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.pr-xl-3 {
		padding-right: 1rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pl-xl-3 {
		padding-left: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.pr-xl-4 {
		padding-right: 1.5rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pl-xl-4 {
		padding-left: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pr-xl-5 {
		padding-right: 3rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.pl-xl-5 {
		padding-left: 3rem !important;
	}
	.m-xl-n1 {
		margin: -.25rem !important;
	}
	.mt-xl-n1 {
		margin-top: -.25rem !important;
	}
	.my-xl-n1 {
		margin-top: -.25rem !important;
		margin-bottom: -.25rem !important;
	}
	.mr-xl-n1 {
		margin-right: -.25rem !important;
	}
	.mx-xl-n1 {
		margin-right: -.25rem !important;
		margin-left: -.25rem !important;
	}
	.mb-xl-n1 {
		margin-bottom: -.25rem !important;
	}
	.ml-xl-n1 {
		margin-left: -.25rem !important;
	}
	.m-xl-n2 {
		margin: -.5rem !important;
	}
	.mt-xl-n2 {
		margin-top: -.5rem !important;
	}
	.my-xl-n2 {
		margin-top: -.5rem !important;
		margin-bottom: -.5rem !important;
	}
	.mr-xl-n2 {
		margin-right: -.5rem !important;
	}
	.mx-xl-n2 {
		margin-right: -.5rem !important;
		margin-left: -.5rem !important;
	}
	.mb-xl-n2 {
		margin-bottom: -.5rem !important;
	}
	.ml-xl-n2 {
		margin-left: -.5rem !important;
	}
	.m-xl-n3 {
		margin: -1rem !important;
	}
	.mt-xl-n3 {
		margin-top: -1rem !important;
	}
	.my-xl-n3 {
		margin-top: -1rem !important;
		margin-bottom: -1rem !important;
	}
	.mr-xl-n3 {
		margin-right: -1rem !important;
	}
	.mx-xl-n3 {
		margin-right: -1rem !important;
		margin-left: -1rem !important;
	}
	.mb-xl-n3 {
		margin-bottom: -1rem !important;
	}
	.ml-xl-n3 {
		margin-left: -1rem !important;
	}
	.m-xl-n4 {
		margin: -1.5rem !important;
	}
	.mt-xl-n4 {
		margin-top: -1.5rem !important;
	}
	.my-xl-n4 {
		margin-top: -1.5rem !important;
		margin-bottom: -1.5rem !important;
	}
	.mr-xl-n4 {
		margin-right: -1.5rem !important;
	}
	.mx-xl-n4 {
		margin-right: -1.5rem !important;
		margin-left: -1.5rem !important;
	}
	.mb-xl-n4 {
		margin-bottom: -1.5rem !important;
	}
	.ml-xl-n4 {
		margin-left: -1.5rem !important;
	}
	.m-xl-n5 {
		margin: -3rem !important;
	}
	.mt-xl-n5 {
		margin-top: -3rem !important;
	}
	.my-xl-n5 {
		margin-top: -3rem !important;
		margin-bottom: -3rem !important;
	}
	.mr-xl-n5 {
		margin-right: -3rem !important;
	}
	.mx-xl-n5 {
		margin-right: -3rem !important;
		margin-left: -3rem !important;
	}
	.mb-xl-n5 {
		margin-bottom: -3rem !important;
	}
	.ml-xl-n5 {
		margin-left: -3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mr-xl-auto {
		margin-right: auto !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ml-xl-auto {
		margin-left: auto !important;
	}
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

/*----------------------------
      Helper Classes
----------------------------*/
.col-padding-0>.row {
  margin: 0;
}

.col-padding-0,
.col-padding-0>.row>[class*="col-"] {
  padding: 0;
}

.col-padding-5>.row {
  margin-right: -5px;
  margin-left: -5px;
}

.col-padding-5,
.col-padding-5>.row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.inner-padding {
  padding: 30px 65px;
}

@media (min-width:1400px) {
  .inner-padding {
      padding: 70px 140px;
  }
}

.vertical-align-center {
  position: relative;
  top: 50%;
  @include prefix(transform, translateY(-50%), webkit moz ms o);
}

.background-banner {
  min-height: 500px;
}

.width-auto {
  width: auto !important;
}

.lh-1 {
  line-height: 1 !important;
}

.vh-100 {
  height: 100vh !important;
}

.align-v {
  display: flex;
  align-items: center;
}

.align-v-h {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fz-13 {
  font-size: 13px;
}

.fz-16 {
  font-size: 16px;
  line-height: 26px;
}

.border-top {
  border-top: 1px solid #e9e9e9;
}

@media (min-width: 992px) {
  .col-lg-5th {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
  }
}

/*  margin Top */
.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

/* Margin Bottom */

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mt--100 {
  margin-top: -100px;
}

/* Margin Right */

.mr-0 {
  margin-right: 0 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

/* Margin Left */

.ml-0 {
  margin-left: 0 !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

/* padding Top */
.pb-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

/*  Padding Bottom */
.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

/* padding Right */

.pr-0 {
  padding-right: 0 !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

/* padding Left */

.pl-0 {
  padding-left: 0 !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

@media (max-width:992px) {

  .text-center-xs-sm-md {
      text-align: center !important;
  }
}

/* Large Devices */
@include lg-screens {
  .inner-padding {
      padding: 20px 50px;
  }
}

/* Medium Devices */
@include md-screens {
  section {
      padding: 75px 0;
  }

  .inner-padding {
      padding: 100px 50px !important;
  }

  .mt-30 {
      margin-top: calc(40px/1.5) !important;
  }

  .mt-40 {
      margin-top: calc(40px/1.5) !important;
  }

  .mt-50 {
      margin-top: calc(50px/1.5) !important;
  }

  .mt-60 {
      margin-top: calc(60px/1.5) !important;
  }

  .mt-70 {
      margin-top: calc(70px/1.5) !important;
  }

  .mt-80 {
      margin-top: calc(80px/1.5) !important;
  }

  .mt-90 {
      margin-top: calc(90px/1.5) !important;
  }

  .mt-100 {
      margin-top: calc(100px/1.5) !important;
  }

  .mb-30 {
      margin-bottom: calc(30px/1.5) !important;
  }

  .mb-40 {
      margin-bottom: calc(40px/1.5) !important;
  }

  .mb-50 {
      margin-bottom: calc(50px/1.5) !important;
  }

  .mb-60 {
      margin-bottom: calc(60px/1.5) !important;
  }

  .mb-70 {
      margin-bottom: calc(70px/1.5) !important;
  }

  .mb-80 {
      margin-bottom: calc(80px/1.5) !important;
  }

  .mb-90 {
      margin-bottom: calc(90px/1.5) !important;
  }

  .mb-100 {
      margin-bottom: calc(100px/1.5) !important;
  }

  /* Margin Right */

  .mr-30 {
      margin-right: calc(30px/1.5) !important;
  }

  .mr-40 {
      margin-right: calc(40px/1.5) !important;
  }

  .mr-50 {
      margin-right: calc(50px/1.5) !important;
  }

  /* Margin Left */
  .ml-30 {
      margin-left: calc(30px/1.5) !important;
  }

  .ml-40 {
      margin-left: calc(40px/1.5) !important;
  }

  .ml-50 {
      margin-left: calc(50px/1.5) !important;
  }

  /* padding Top */

  .pt-30 {
      padding-top: calc(30px/1.5) !important;
  }

  .pt-40 {
      padding-top: calc(40px/1.5) !important;
  }

  .pt-50 {
      padding-top: calc(50px/1.5) !important;
  }

  .pt-60 {
      padding-top: calc(60px/1.5) !important;
  }

  .pt-70 {
      padding-top: calc(70px/1.5) !important;
  }

  .pt-80 {
      padding-top: calc(80px/1.5) !important;
  }

  .pt-90 {
      padding-top: calc(90px/1.5) !important;
  }

  .pt-100 {
      padding-top: calc(100px/1.5) !important;
  }

  .pt-110 {
      padding-top: calc(110px/1.5) !important;
  }

  .pt-120 {
      padding-top: calc(120px/1.5) !important;
  }

  .pt-130 {
      padding-top: calc(140px/1.5) !important;
  }

  .pt-140 {
      padding-top: calc(140px/1.5) !important;
  }

  .pt-150 {
      padding-top: calc(150px/1.5) !important;
  }

  .pt-160 {
      padding-top: calc(160px/1.5) !important;
  }

  .pt-170 {
      padding-top: calc(170px/1.5) !important;
  }

  /*  Padding Bottom */
  .pb-30 {
      padding-bottom: calc(30px/1.5) !important;
  }

  .pb-40 {
      padding-bottom: calc(40px/1.5) !important;
  }

  .pb-50 {
      padding-bottom: calc(50px/1.5) !important;
  }

  .pb-60 {
      padding-bottom: calc(60px/1.5) !important;
  }

  .pb-70 {
      padding-bottom: calc(70px/1.5) !important;
  }

  .pb-80 {
      padding-bottom: calc(80px/1.5) !important;
  }

  .pb-90 {
      padding-bottom: calc(90px/1.5) !important;
  }

  .pb-100 {
      padding-bottom: calc(100px/1.5) !important;
  }

  .pb-110 {
      padding-bottom: calc(110px/1.5) !important;
  }

  .pb-120 {
      padding-bottom: calc(120px/1.5) !important;
  }

  .pb-130 {
      padding-bottom: calc(130px/1.5) !important;
  }

  .pb-140 {
      padding-bottom: calc(140px/1.5) !important;
  }

  .pb-150 {
      padding-bottom: calc(150px/1.5) !important;
  }

  .pb-160 {
      padding-bottom: calc(160px/1.5) !important;
  }

  .pb-170 {
      padding-bottom: calc(170px/1.5) !important;
  }
}

/* Mobile Phones and tablets */
@include xs-sm-screens {
  section {
      padding: 50px 0;
  }

  .inner-padding {
      padding: 20px !important;
  }

  .text-center-xs-sm {
      text-align: center !important;
  }

  .mt-30 {
      margin-top: calc(30px/2) !important;
  }

  .mt-40 {
      margin-top: calc(40px/2) !important;
  }

  .mt-50 {
      margin-top: calc(50px/2) !important;
  }

  .mt-60 {
      margin-top: calc(60px/2) !important;
  }

  .mt-70 {
      margin-top: calc(70px/2) !important;
  }

  .mt-80 {
      margin-top: calc(80px/2) !important;
  }

  .mt-90 {
      margin-top: calc(90px/2) !important;
  }

  .mt-100 {
      margin-top: calc(100px/2) !important;
  }

  .mb-30 {
      margin-bottom: calc(30px/2) !important;
  }

  .mb-40 {
      margin-bottom: calc(40px/2) !important;
  }

  .mb-50 {
      margin-bottom: calc(50px/2) !important;
  }

  .mb-60 {
      margin-bottom: calc(60px/2) !important;
  }

  .mb-70 {
      margin-bottom: calc(70px/2) !important;
  }

  .mb-80 {
      margin-bottom: calc(80px/2) !important;
  }

  .mb-90 {
      margin-bottom: calc(90px/2) !important;
  }

  .mb-100 {
      margin-bottom: calc(100px/2) !important;
  }

  /* Margin Right */

  .mr-30 {
      margin-right: calc(30px/2) !important;
  }

  .mr-40 {
      margin-right: calc(40px/2) !important;
  }

  .mr-50 {
      margin-right: calc(50px/2) !important;
  }

  /* Margin Left */
  .ml-30 {
      margin-left: calc(30px/2) !important;
  }

  .ml-40 {
      margin-left: calc(40px/2) !important;
  }

  .ml-50 {
      margin-left: calc(50px/2) !important;
  }

  /* padding Top */

  .pt-30 {
      padding-top: calc(30px/2) !important;
  }

  .pt-40 {
      padding-top: calc(40px/2) !important;
  }

  .pt-50 {
      padding-top: calc(50px/2) !important;
  }

  .pt-60 {
      padding-top: calc(60px/2) !important;
  }

  .pt-70 {
      padding-top: calc(70px/2) !important;
  }

  .pt-80 {
      padding-top: calc(80px/2) !important;
  }

  .pt-90 {
      padding-top: calc(90px/2) !important;
  }

  .pt-100 {
      padding-top: calc(100px/2) !important;
  }

  .pt-110 {
      padding-top: calc(110px/2) !important;
  }

  .pt-120 {
      padding-top: calc(120px/2) !important;
  }

  .pt-130 {
      padding-top: calc(140px/2) !important;
  }

  .pt-140 {
      padding-top: calc(140px/2) !important;
  }

  .pt-150 {
      padding-top: calc(150px/2) !important;
  }

  .pt-160 {
      padding-top: calc(160px/2) !important;
  }

  .pt-170 {
      padding-top: calc(170px/2) !important;
  }

  /*  Padding Bottom */
  .pb-30 {
      padding-bottom: calc(30px/2) !important;
  }

  .pb-40 {
      padding-bottom: calc(40px/2) !important;
  }

  .pb-50 {
      padding-bottom: calc(50px/2) !important;
  }

  .pb-60 {
      padding-bottom: calc(60px/2) !important;
  }

  .pb-70 {
      padding-bottom: calc(70px/2) !important;
  }

  .pb-80 {
      padding-bottom: calc(80px/2) !important;
  }

  .pb-90 {
      padding-bottom: calc(90px/2) !important;
  }

  .pb-100 {
      padding-bottom: calc(100px/2) !important;
  }

  .pb-110 {
      padding-bottom: calc(110px/2) !important;
  }

  .pb-120 {
      padding-bottom: calc(120px/2) !important;
  }

  .pb-130 {
      padding-bottom: calc(130px/2) !important;
  }

  .pb-140 {
      padding-bottom: calc(140px/2) !important;
  }

  .pb-150 {
      padding-bottom: calc(150px/2) !important;
  }

  .pb-160 {
      padding-bottom: calc(160px/2) !important;
  }

  .pb-170 {
      padding-bottom: calc(170px/2) !important;
  }
}