@import "../../../../scss/variables.scss";

/*Big smartphones [426px -> 600px]*/
.currentProjects-main-wrapper {
	color: #fff;
}
.current-project-heading {
	display: flex;
	justify-content: center;
	h1 {
		color: #fff;
		font-size: 22px;
		border-bottom: 1px solid $pink;
	}
}
.currentProjects-cards-wrapper {
	margin-top: 220px;
	display: flex;
	justify-content: space-evenly;
}
@media only screen and (max-width: 600px) {
	.currentProjects-cards-wrapper {
		display: block;
	}
}
