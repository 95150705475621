.CurrentCouncilBodyCard-main-wrapper {
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
}
.card-Img {
	border: 2.5px solid rgb(228, 1, 228);
	border-radius: 30px;
	margin-bottom: 10px;
	width: 180px;
}
.Card-Heading {
	color: #fff;
	font-weight: 600;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.Card-Text {
	color: rgb(148, 146, 146);
	display: flex;
	align-items: center;
	justify-content: center;
	font-style: italic;
	font-size: 14px;
	transform: translateX(7px);
}
.Twitter-Icon {
	width: 15px;
	margin-left: 10px;
	cursor: pointer;
}
@media only screen and (max-width: 600px) {
	.CurrentCouncilBodyCard-main-wrapper {
		width: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		margin: auto;
		margin-top: 30px;
	}
}
