// .loader {
//     width: 1.1em;
//     height: 1.1em;
//     display: flex;

//     &::after {
//         content: '';
//         border: 0.2em solid transparent;
//         border-left-color: currentColor;
//         border-top-color: currentColor;
//         flex: 1;
//         border-radius: 50%;
//         animation: 0.2s infinite Loader linear;
//     }
// }

// .btn-normal {
//     border: none;
//     outline: none;
//     font-family: inherit;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     font-weight: 500;
//     background-color: transparent;
//     padding: 0;
//     gap: 0.5em;
//     white-space: nowrap;
//     color: inherit;
//     line-height: 1em;

//     &:not(:disabled) {
//         cursor: pointer;
//     }

//     &:disabled {
//         cursor: not-allowed;
//     }
// }

// .hover {
//     position: relative;
//     z-index: 1;
//     &:not(:disabled) {
//         &::before {
//             content: '';
//             // z-index: -1;
//             position: absolute;
//             top: 0;
//             left: 0;
//             right: 0;
//             bottom: 0;
//             border-radius: inherit;
//         }
//     }

//     &::after {
//         background: currentColor;
//         opacity: 0;
//     }

//     &:hover::after {
//         opacity: 0.1;
//     }

//     &:active,
//     &.active {
//         &::after {
//             opacity: 0.05;
//         }
//     }
// }

// .btn-icon {
//     font-size: 1rem;
//     @extend .btn-normal, .hover;
//     width: 2.857em;
//     height: 2.857em;
//     border-radius: 3em;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     >span,
//     >svg,
//     >img {
//         width: 1.5em;
//         max-height: 1.5em;
//     }

//     >span {
//         background: currentColor;
//     }

//     &.no-space {
//         margin: -0.69em;
//     }
// }

// .btn-border {
//     @extend .btn-normal, .hover;

//     &::before {
//         border: 0.15em solid;
//     }
// }

// .btn-circle {
//     padding: 1.4em 1.75em;
//     border-radius: 3em;
// }


// .btn-primary {
//     @extend .btn-normal, .hover;
//     background: linear-gradient(to right, #2196f3, #4db6ac);
//     padding: 1.25em 1.75em;
//     border-radius: 0.2em;
//     min-width: 16em;
//     overflow: hidden;
//     &::before {
//         border: 0.15em solid;
//         border-width: 0.15em;
//         border-image-slice: 1;
//         border-image-source: linear-gradient(to right, #2196f3, #4db6ac);
//     }
// }

// .btn-purple {
//     background: linear-gradient(to right, #9c27b0, #6a1b9a);
//     &::before {
//         border-image-source: linear-gradient(to right, #9c27b0, #6a1b9a)
//     }
// }

// @keyframes Loader {
//     0% {
//         transform: rotate(0);
//     }

//     100% {
//         transform: rotate(360deg);
//     }
// }

.loader {
    width: 1.1em;
    height: 1.1em;
    display: flex;

    &::after {
        content: '';
        border: 0.2em solid transparent;
        border-left-color: currentColor;
        border-top-color: currentColor;
        flex: 1;
        border-radius: 50%;
        animation: 0.2s infinite Loader linear;
    }
}

.btn-normal {
    font-size: inherit;
    border: none;
    outline: none;
    font-family: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background-color: transparent;
    padding: 0;
    gap: 0.5em;
    white-space: nowrap;
    color: inherit;
    line-height: 1em;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.hover {
    position: relative;
    z-index: 1;
    &:not(:disabled) {
        &::after,
        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            border-radius: inherit;
        }
    }

    &::after {
        background: currentColor;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:hover::after {
        opacity: 0.1;
    }

    &:active,
    &.active {
        &::after {
            opacity: 0.05;
        }
    }
}

.btn-icon {
    @extend .btn-normal, .hover;
    width: 2.857em;
    height: 2.857em;
    border-radius: 3em;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >span,
    >svg,
    >img {
        width: 1.5em;
        max-height: 1.5em;
    }

    >span {
        background: currentColor;
    }

    &.no-space {
        margin: -0.69em;
    }
}

.btn-border {
    @extend .btn-normal, .hover;
}


.btn-primary {
    @extend .btn-normal, .hover;
    padding: 1.25em 1.75em;
    border-radius: 0.2em;
    min-width: 16em;
    &::before {
        position: absolute;
        top: -0.15em;
        right: -0.15em;
        bottom: -0.15em;
        left: -0.15em;
        @extend .bg-gradiant-primary;
    }
    &::after {
        background: inherit;
        opacity: 1;
    }
    &.bg-gradiant-secondary::before {
        @extend .bg-gradiant-secondary;
    }
}
.btn-circle {
    padding: 1.4em 1.75em;
    border-radius: 3em;
    min-width: initial;
    @extend .btn-primary;
}

.btn-text {
    @extend .btn-normal;
    &:hover {
        text-decoration: underline;
    }
}

@keyframes Loader {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}