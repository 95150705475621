@import "../../../../../../scss/variables.scss";

.news-main-wrapper {
	width: 100%;
}
.news-inner-wrapper {
	display: flex;
	align-items: center;
	margin: auto;
}
.news-items {
	width: 100%;
	.item-outer {
		font-size: 17px;
		font-weight: 400;
		text-decoration: none;
		text-decoration-color: $light;
		transition: all 0.4s ease;

		cursor: pointer;
		
		.item-inner {
			margin-bottom: 10px;
			font-style: italic;
			color: $light-dim;

			.title {
				font-style:normal;
				font-weight: 500;
				color: $light;
				&.primary {
					color: $pink;
				}
			}
		}

		&:hover {
			color: $light;
			text-decoration: underline;
		}
	}
}
.news-rightSide {
	width: 30%;
	margin-top: 100px;
	p {
		color: rgb(219, 209, 209);
		font-size: 16px;
		text-align: center;
	}
}
.TwitterImg {
	width: 200px;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
}
@media screen and (max-width: 480px) {
	.news-main-wrapper {
		h1 {
			color: #fff;
			font-size: 20px;
			font-family: akira;
			margin: auto;
			width: 90%;
			margin-bottom: 20px;
		}
	}
	.news-inner-wrapper {
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: auto;
	}
	.news-leftSide {
		width: 100%;
		p {
			color: rgb(233, 231, 231);
			font-size: 16px;
			line-height: 30px;
			span {
				color: rgb(216, 11, 216);
				font-size: 18px;
				font-weight: 400;
				cursor: pointer;
			}
			b {
				font-weight: 400;
				font-size: 14px;
			}
		}
	}
	.news-rightSide {
		width: 100%;
		margin-top: 30px;
		p {
			color: rgb(219, 209, 209);
			font-size: 16px;
			text-align: center;
		}
	}
	.TwitterImg {
		width: 200px;
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: auto;
	}
}
