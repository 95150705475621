@import "../../../../scss/variables.scss";

/* roadmap-main-wrapper */
.roadmap-main-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 65px;
	padding:30px;
}
.roadmap-inner-wrapper {
	width: 85%;
	padding: 40px 0px 8px 0px;
	border-top: 1px solid gray;
	h1 {
		color: #fff;
		border-left: 2px solid $blue;
		padding-left: 15px;
		font-size: 26px;
		font-weight: 600;
	}
	p {
		color: $light;
		padding-left: 30px;
	}
}
