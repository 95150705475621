@import "../../../../scss/variables.scss";

.originStory-main-wrapper {
	width: 100%;
}
.originStory-inner-wrapper {
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	padding-top: 60px;
	padding-bottom: 120px;

	.origin-title {
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 15px;
		width: 100%;
		margin-top: 50px;
		span {
			color: $pink;
			cursor: pointer;
		}
	}
	P {
		color: $light;
		font-size: 17px;
		margin-bottom: 20px;
		width: 100%;
		font-weight: 300;
	}
	p {
		span {
			color: $blue;
			font-weight: 400;
			cursor: pointer;
		}
		a {
			text-decoration: none;
		}
	}
}
@media screen and (max-width: 480px) {
	.originStory-inner-wrapper {
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		padding-top: 20px;
		padding-bottom: 40px;
		h1 {
			color: #fff;
			font-size: 22px;
			border-bottom: 1px solid $pink;
			margin-bottom: 5px;
		}
		h3 {
			color: #fff;
			font-size: 18px;
			margin-bottom: 10px;
			width: 100%;
			margin-top: 20px;
			span {
				color: $pink;
				cursor: pointer;
			}
		}
		P {
			color: rgb(192, 188, 188);
			font-size: 14px;
			margin-bottom: 10px;
			width: 100%;
			font-weight: 100;
		}
		p {
			span {
				color: $blue;
				font-weight: 300;
				cursor: pointer;
			}
		}
	}
}
