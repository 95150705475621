@import "../../../../scss/variables.scss";
/* sideNav-main-wrapper */
/* sideNav-heading */
/* sideNav-list */
/* join-lounge-btn-wrapper */
/* join-lounge-btn */
.sideNav-main-wrapper {
	transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	-moz-transition: all 0.5s ease 0s;
	-ms-transition: all 0.5s ease 0s;
	-o-transition: all 0.5s ease 0s;
	overflow: hidden;
	color: rgb(167, 161, 161);
	border-bottom: 2px solid $pink;
	padding-bottom: 30px;
	.links-w {
		display: none;
	}
}
.sideNav-heading {
	width: 90%;
	margin: auto;
	padding: 20px;
	padding-bottom: 10px;
	font-size: 24px;
	font-weight: 600;
	color: rgb(199, 199, 199);
	color: rgb(167, 161, 161);
	transition: color 0.4s ease;

	&:hover {
		color: $pink;
	}
}
.sideNav-list {
	width: 90%;
	margin: auto;
	list-style: none;
	padding-left: 50px;

	-webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */

	a {
		text-decoration: none;
		color: rgb(167, 161, 161);

		&.active {
			color: $pink;
		}

		&:hover {
			color: $pink-pale;
		}
	}

	li {
		padding-bottom: 10px;
		font-size: 18px;
		.coming-soon {
			position: relative;
			top: -15px;
			left: -20px;
			font-size: 10px;
			color: $pink;
		}
	}
}
.join-lounge-btn-wrapper {
	width: 90%;
	margin: auto;
	margin-top: 15px;
}
.join-lounge-btn {
	background-color: $pink-dim;
	margin-left: 10px;
	padding: 10px 22px;
	border-radius: 30px;
	cursor: pointer;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	display: inline-block;
	color: white;
	transition: background-color 0.4s;
	&:hover {
		background-color: $pink;
	}
}
@media only screen and (max-width: 600px) {
	.sideNav-main-wrapper {
		.links-w {
			display: flex;
			align-items: center;
			width: 90%;
			margin: auto;
			padding-left: 15px;
			margin-top: 30px;
			a {
				margin-right: 20px;
			}
			img {
				width: 30px;
				cursor: pointer;
			}
		}
	}
}
