.of-cover{
  object-fit: cover;
}
.tx-underline {
  text-decoration: underline;
}
.d-webkit-box{
  display: -webkit-box;
  line-height: 1.25em;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: 2.5em;
  &.lines-1{
    -webkit-line-clamp: 1;
    max-height: 1.25em;
  }
  &.lines-3{
    max-height: 3.75em;
    -webkit-line-clamp: 3;
  }
}
.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}
.normal-p{
  padding: 0.1px 0;
}
.list-unstyled{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-text{
  cursor: text;
}

.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden{
  overflow: hidden !important;
}
.overflow-initial{
  overflow: initial !important;
}

.line-height-1{
  line-height: 1em;
}
.line-height-2{
  line-height: 1.5em;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}


.d-flex {
  display: flex !important;
}
.d-inline-flex{
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  right: 0 !important;
}

.start-50 {
  right: 50% !important;
}

.start-100 {
  right: 100% !important;
}

.end-0 {
  left: 0 !important;
}

.end-50 {
  left: 50% !important;
}

.end-100 {
  left: 100% !important;
}

.translate-middle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}


.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.h-100{
  height: 100% !important;
}
.min-h-100 {
  min-height: 100% !important;
}
.vh-100 {
  height: var(--app-height) !important;
}
.min-vh-100 {
  min-height: var(--app-height) !important;
}


.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.5rem !important;
}

.gap-2 {
  gap: 1rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.5rem !important;
}

.gap-5 {
  gap: 3.5rem !important;
}

.jc-start {
  justify-content: flex-start !important;
}

.jc-end {
  justify-content: flex-end !important;
}

.jc-center {
  justify-content: center !important;
}

.jc-between {
  justify-content: space-between !important;
}

.jc-around {
  justify-content: space-around !important;
}

.jc-evenly {
  justify-content: space-evenly !important;
}

.ai-start {
  align-items: flex-start !important;
}

.ai-end {
  align-items: flex-end !important;
}

.ai-center {
  align-items: center !important;
}

.ai-baseline {
  align-items: baseline !important;
}

.ai-stretch {
  align-items: stretch !important;
}


.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.5rem !important;
}

.m-5 {
  margin: 3.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-4 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-5 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-5 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.5rem !important;
}

.mt-5 {
  margin-top: 3.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-left: 0 !important;
}

.me-1 {
  margin-left: 0.5rem !important;
}

.me-2 {
  margin-left: 1rem !important;
}

.me-3 {
  margin-left: 1.5rem !important;
}

.me-4 {
  margin-left: 2.5rem !important;
}

.me-5 {
  margin-left: 3.5rem !important;
}

.me-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.5rem !important;
}

.mb-5 {
  margin-bottom: 3.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-right: 0 !important;
}

.ms-1 {
  margin-right: 0.5rem !important;
}

.ms-2 {
  margin-right: 1rem !important;
}

.ms-3 {
  margin-right: 1.5rem !important;
}

.ms-4 {
  margin-right: 2.5rem !important;
}

.ms-5 {
  margin-right: 3.5rem !important;
}

.ms-auto {
  margin-right: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.5rem !important;
}

.p-5 {
  padding: 3.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-5 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-5 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.5rem !important;
}

.pt-5 {
  padding-top: 3.5rem !important;
}

.pe-0 {
  padding-left: 0 !important;
}

.pe-1 {
  padding-left: 0.5rem !important;
}

.pe-2 {
  padding-left: 1rem !important;
}

.pe-3 {
  padding-left: 1.5rem !important;
}

.pe-4 {
  padding-left: 2.5rem !important;
}

.pe-5 {
  padding-left: 3.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.5rem !important;
}

.pb-5 {
  padding-bottom: 3.5rem !important;
}

.ps-0 {
  padding-right: 0 !important;
}

.ps-1 {
  padding-right: 0.5rem !important;
}

.ps-2 {
  padding-right: 1rem !important;
}

.ps-3 {
  padding-right: 1.5rem !important;
}

.ps-4 {
  padding-right: 2.5rem !important;
}

.ps-5 {
  padding-right: 3.5rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}
.fw-semibold{
  font-weight: 600 !important;
}
.fw-mediam{
    font-weight: 500 !important;
}
.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.tx-start {
  text-align: right !important;
}

.tx-end {
  text-align: left !important;
}

.tx-center {
  text-align: center !important;
}

.tx-decoration-line-through {
  text-decoration: line-through !important;
}

.tx-lowercase {
  text-transform: lowercase !important;
}

.tx-uppercase {
  text-transform: uppercase !important;
}

.tx-capitalize {
  text-transform: capitalize !important;
}

.tx-nowrap {
  white-space: nowrap !important;
}
.tx-wrap{
  white-space: normal !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .w-sm-initial{
    width: initial !important;
  }
  
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }


  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.5rem !important;
  }

  .gap-sm-2 {
    gap: 1rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2.5rem !important;
  }

  .gap-sm-5 {
    gap: 3.5rem !important;
  }
  .jc-sm-start {
    justify-content: flex-start !important;
  }

  .jc-sm-end {
    justify-content: flex-end !important;
  }

  .jc-sm-center {
    justify-content: center !important;
  }

  .jc-sm-between {
    justify-content: space-between !important;
  }

  .jc-sm-around {
    justify-content: space-around !important;
  }

  .jc-sm-evenly {
    justify-content: space-evenly !important;
  }

  .ai-sm-start {
    align-items: flex-start !important;
  }

  .ai-sm-end {
    align-items: flex-end !important;
  }

  .ai-sm-center {
    align-items: center !important;
  }

  .ai-sm-stretch {
    align-items: stretch !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.5rem !important;
  }

  .m-sm-2 {
    margin: 1rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.5rem !important;
  }

  .m-sm-5 {
    margin: 3.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-sm-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-2 {
    margin-top: 1rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-left: 0 !important;
  }

  .me-sm-1 {
    margin-left: 0.5rem !important;
  }

  .me-sm-2 {
    margin-left: 1rem !important;
  }

  .me-sm-3 {
    margin-left: 1.5rem !important;
  }

  .me-sm-4 {
    margin-left: 2.5rem !important;
  }

  .me-sm-5 {
    margin-left: 3.5rem !important;
  }

  .me-sm-auto {
    margin-left: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-right: 0 !important;
  }

  .ms-sm-1 {
    margin-right: 0.5rem !important;
  }

  .ms-sm-2 {
    margin-right: 1rem !important;
  }

  .ms-sm-3 {
    margin-right: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-right: 2.5rem !important;
  }

  .ms-sm-5 {
    margin-right: 3.5rem !important;
  }

  .ms-sm-auto {
    margin-right: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.5rem !important;
  }

  .p-sm-2 {
    padding: 1rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.5rem !important;
  }

  .p-sm-5 {
    padding: 3.5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-sm-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-4 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 1rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3.5rem !important;
  }

  .pe-sm-0 {
    padding-left: 0 !important;
  }

  .pe-sm-1 {
    padding-left: 0.5rem !important;
  }

  .pe-sm-2 {
    padding-left: 1rem !important;
  }

  .pe-sm-3 {
    padding-left: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-left: 2.5rem !important;
  }

  .pe-sm-5 {
    padding-left: 3.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3.5rem !important;
  }

  .ps-sm-0 {
    padding-right: 0 !important;
  }

  .ps-sm-1 {
    padding-right: 0.5rem !important;
  }

  .ps-sm-2 {
    padding-right: 1rem !important;
  }

  .ps-sm-3 {
    padding-right: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-right: 2.5rem !important;
  }

  .ps-sm-5 {
    padding-right: 3.5rem !important;
  }

  .tx-sm-start {
    text-align: right !important;
  }

  .tx-sm-end {
    text-align: left !important;
  }

  .tx-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }


  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.5rem !important;
  }

  .gap-md-2 {
    gap: 1rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2.5rem !important;
  }

  .gap-md-5 {
    gap: 3.5rem !important;
  }
  .jc-md-start {
    justify-content: flex-start !important;
  }

  .jc-md-end {
    justify-content: flex-end !important;
  }

  .jc-md-center {
    justify-content: center !important;
  }

  .jc-md-between {
    justify-content: space-between !important;
  }

  .jc-md-around {
    justify-content: space-around !important;
  }

  .jc-md-evenly {
    justify-content: space-evenly !important;
  }

  .ai-md-start {
    align-items: flex-start !important;
  }

  .ai-md-end {
    align-items: flex-end !important;
  }

  .ai-md-center {
    align-items: center !important;
  }

  .ai-md-baseline {
    align-items: baseline !important;
  }

  .ai-md-stretch {
    align-items: stretch !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.5rem !important;
  }

  .m-md-2 {
    margin: 1rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.5rem !important;
  }

  .m-md-5 {
    margin: 3.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-md-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-4 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.5rem !important;
  }

  .mt-md-2 {
    margin-top: 1rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-left: 0 !important;
  }

  .me-md-1 {
    margin-left: 0.5rem !important;
  }

  .me-md-2 {
    margin-left: 1rem !important;
  }

  .me-md-3 {
    margin-left: 1.5rem !important;
  }

  .me-md-4 {
    margin-left: 2.5rem !important;
  }

  .me-md-5 {
    margin-left: 3.5rem !important;
  }

  .me-md-auto {
    margin-left: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-right: 0 !important;
  }

  .ms-md-1 {
    margin-right: 0.5rem !important;
  }

  .ms-md-2 {
    margin-right: 1rem !important;
  }

  .ms-md-3 {
    margin-right: 1.5rem !important;
  }

  .ms-md-4 {
    margin-right: 2.5rem !important;
  }

  .ms-md-5 {
    margin-right: 3.5rem !important;
  }

  .ms-md-auto {
    margin-right: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.5rem !important;
  }

  .p-md-2 {
    padding: 1rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.5rem !important;
  }

  .p-md-5 {
    padding: 3.5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-md-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-4 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.5rem !important;
  }

  .pt-md-2 {
    padding-top: 1rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3.5rem !important;
  }

  .pe-md-0 {
    padding-left: 0 !important;
  }

  .pe-md-1 {
    padding-left: 0.5rem !important;
  }

  .pe-md-2 {
    padding-left: 1rem !important;
  }

  .pe-md-3 {
    padding-left: 1.5rem !important;
  }

  .pe-md-4 {
    padding-left: 2.5rem !important;
  }

  .pe-md-5 {
    padding-left: 3.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3.5rem !important;
  }

  .ps-md-0 {
    padding-right: 0 !important;
  }

  .ps-md-1 {
    padding-right: 0.5rem !important;
  }

  .ps-md-2 {
    padding-right: 1rem !important;
  }

  .ps-md-3 {
    padding-right: 1.5rem !important;
  }

  .ps-md-4 {
    padding-right: 2.5rem !important;
  }

  .ps-md-5 {
    padding-right: 3.5rem !important;
  }

  .tx-md-start {
    text-align: right !important;
  }

  .tx-md-end {
    text-align: left !important;
  }

  .tx-md-center {
    text-align: center !important;
  }

  .vh-md-100{
    height: var(--app-height);
  }
}
@media (min-width: 992px) {

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }


  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.5rem !important;
  }

  .gap-lg-2 {
    gap: 1rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2.5rem !important;
  }

  .gap-lg-5 {
    gap: 3.5rem !important;
  }
  .jc-lg-start {
    justify-content: flex-start !important;
  }

  .jc-lg-end {
    justify-content: flex-end !important;
  }

  .jc-lg-center {
    justify-content: center !important;
  }

  .jc-lg-between {
    justify-content: space-between !important;
  }

  .jc-lg-around {
    justify-content: space-around !important;
  }

  .jc-lg-evenly {
    justify-content: space-evenly !important;
  }

  .ai-lg-start {
    align-items: flex-start !important;
  }

  .ai-lg-end {
    align-items: flex-end !important;
  }

  .ai-lg-center {
    align-items: center !important;
  }

  .ai-lg-baseline {
    align-items: baseline !important;
  }

  .ai-lg-stretch {
    align-items: stretch !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.5rem !important;
  }

  .m-lg-2 {
    margin: 1rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.5rem !important;
  }

  .m-lg-5 {
    margin: 3.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-lg-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-2 {
    margin-top: 1rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-left: 0 !important;
  }

  .me-lg-1 {
    margin-left: 0.5rem !important;
  }

  .me-lg-2 {
    margin-left: 1rem !important;
  }

  .me-lg-3 {
    margin-left: 1.5rem !important;
  }

  .me-lg-4 {
    margin-left: 2.5rem !important;
  }

  .me-lg-5 {
    margin-left: 3.5rem !important;
  }

  .me-lg-auto {
    margin-left: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-right: 0 !important;
  }

  .ms-lg-1 {
    margin-right: 0.5rem !important;
  }

  .ms-lg-2 {
    margin-right: 1rem !important;
  }

  .ms-lg-3 {
    margin-right: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-right: 2.5rem !important;
  }

  .ms-lg-5 {
    margin-right: 3.5rem !important;
  }

  .ms-lg-auto {
    margin-right: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.5rem !important;
  }

  .p-lg-2 {
    padding: 1rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.5rem !important;
  }

  .p-lg-5 {
    padding: 3.5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-lg-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-4 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-2 {
    padding-top: 1rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3.5rem !important;
  }

  .pe-lg-0 {
    padding-left: 0 !important;
  }

  .pe-lg-1 {
    padding-left: 0.5rem !important;
  }

  .pe-lg-2 {
    padding-left: 1rem !important;
  }

  .pe-lg-3 {
    padding-left: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-left: 2.5rem !important;
  }

  .pe-lg-5 {
    padding-left: 3.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3.5rem !important;
  }

  .ps-lg-0 {
    padding-right: 0 !important;
  }

  .ps-lg-1 {
    padding-right: 0.5rem !important;
  }

  .ps-lg-2 {
    padding-right: 1rem !important;
  }

  .ps-lg-3 {
    padding-right: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-right: 2.5rem !important;
  }

  .ps-lg-5 {
    padding-right: 3.5rem !important;
  }

  .tx-lg-start {
    text-align: right !important;
  }

  .tx-lg-end {
    text-align: left !important;
  }

  .tx-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.5rem !important;
  }

  .gap-xl-2 {
    gap: 1rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2.5rem !important;
  }

  .gap-xl-5 {
    gap: 3.5rem !important;
  }

  .jc-xl-start {
    justify-content: flex-start !important;
  }

  .jc-xl-end {
    justify-content: flex-end !important;
  }

  .jc-xl-center {
    justify-content: center !important;
  }

  .jc-xl-between {
    justify-content: space-between !important;
  }

  .jc-xl-around {
    justify-content: space-around !important;
  }

  .jc-xl-evenly {
    justify-content: space-evenly !important;
  }

  .ai-xl-start {
    align-items: flex-start !important;
  }

  .ai-xl-end {
    align-items: flex-end !important;
  }

  .ai-xl-center {
    align-items: center !important;
  }

  .ai-xl-baseline {
    align-items: baseline !important;
  }

  .ai-xl-stretch {
    align-items: stretch !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.5rem !important;
  }

  .m-xl-2 {
    margin: 1rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.5rem !important;
  }

  .m-xl-5 {
    margin: 3.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xl-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-2 {
    margin-top: 1rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-left: 0 !important;
  }

  .me-xl-1 {
    margin-left: 0.5rem !important;
  }

  .me-xl-2 {
    margin-left: 1rem !important;
  }

  .me-xl-3 {
    margin-left: 1.5rem !important;
  }

  .me-xl-4 {
    margin-left: 2.5rem !important;
  }

  .me-xl-5 {
    margin-left: 3.5rem !important;
  }

  .me-xl-auto {
    margin-left: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-right: 0 !important;
  }

  .ms-xl-1 {
    margin-right: 0.5rem !important;
  }

  .ms-xl-2 {
    margin-right: 1rem !important;
  }

  .ms-xl-3 {
    margin-right: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-right: 2.5rem !important;
  }

  .ms-xl-5 {
    margin-right: 3.5rem !important;
  }

  .ms-xl-auto {
    margin-right: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.5rem !important;
  }

  .p-xl-2 {
    padding: 1rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.5rem !important;
  }

  .p-xl-5 {
    padding: 3.5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xl-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-4 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-2 {
    padding-top: 1rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3.5rem !important;
  }

  .pe-xl-0 {
    padding-left: 0 !important;
  }

  .pe-xl-1 {
    padding-left: 0.5rem !important;
  }

  .pe-xl-2 {
    padding-left: 1rem !important;
  }

  .pe-xl-3 {
    padding-left: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-left: 2.5rem !important;
  }

  .pe-xl-5 {
    padding-left: 3.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3.5rem !important;
  }

  .ps-xl-0 {
    padding-right: 0 !important;
  }

  .ps-xl-1 {
    padding-right: 0.5rem !important;
  }

  .ps-xl-2 {
    padding-right: 1rem !important;
  }

  .ps-xl-3 {
    padding-right: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-right: 2.5rem !important;
  }

  .ps-xl-5 {
    padding-right: 3.5rem !important;
  }

  .tx-xl-start {
    text-align: right !important;
  }

  .tx-xl-end {
    text-align: left !important;
  }

  .tx-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.5rem !important;
  }

  .gap-xxl-2 {
    gap: 1rem !important;
  }

  .gap-xxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxl-4 {
    gap: 2.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3.5rem !important;
  }
  .jc-xxl-start {
    justify-content: flex-start !important;
  }

  .jc-xxl-end {
    justify-content: flex-end !important;
  }

  .jc-xxl-center {
    justify-content: center !important;
  }

  .jc-xxl-between {
    justify-content: space-between !important;
  }

  .jc-xxl-around {
    justify-content: space-around !important;
  }

  .jc-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .ai-xxl-start {
    align-items: flex-start !important;
  }

  .ai-xxl-end {
    align-items: flex-end !important;
  }

  .ai-xxl-center {
    align-items: center !important;
  }

  .ai-xxl-baseline {
    align-items: baseline !important;
  }

  .ai-xxl-stretch {
    align-items: stretch !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.5rem !important;
  }

  .m-xxl-2 {
    margin: 1rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2.5rem !important;
  }

  .m-xxl-5 {
    margin: 3.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .mx-xxl-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-2 {
    margin-top: 1rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-left: 0 !important;
  }

  .me-xxl-1 {
    margin-left: 0.5rem !important;
  }

  .me-xxl-2 {
    margin-left: 1rem !important;
  }

  .me-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-left: 2.5rem !important;
  }

  .me-xxl-5 {
    margin-left: 3.5rem !important;
  }

  .me-xxl-auto {
    margin-left: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-right: 0 !important;
  }

  .ms-xxl-1 {
    margin-right: 0.5rem !important;
  }

  .ms-xxl-2 {
    margin-right: 1rem !important;
  }

  .ms-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-right: 2.5rem !important;
  }

  .ms-xxl-5 {
    margin-right: 3.5rem !important;
  }

  .ms-xxl-auto {
    margin-right: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.5rem !important;
  }

  .p-xxl-2 {
    padding: 1rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2.5rem !important;
  }

  .p-xxl-5 {
    padding: 3.5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .px-xxl-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-2 {
    padding-top: 1rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3.5rem !important;
  }

  .pe-xxl-0 {
    padding-left: 0 !important;
  }

  .pe-xxl-1 {
    padding-left: 0.5rem !important;
  }

  .pe-xxl-2 {
    padding-left: 1rem !important;
  }

  .pe-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-left: 2.5rem !important;
  }

  .pe-xxl-5 {
    padding-left: 3.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3.5rem !important;
  }

  .ps-xxl-0 {
    padding-right: 0 !important;
  }

  .ps-xxl-1 {
    padding-right: 0.5rem !important;
  }

  .ps-xxl-2 {
    padding-right: 1rem !important;
  }

  .ps-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-right: 2.5rem !important;
  }

  .ps-xxl-5 {
    padding-right: 3.5rem !important;
  }

  .tx-xxl-start {
    text-align: right !important;
  }

  .tx-xxl-end {
    text-align: left !important;
  }

  .tx-xxl-center {
    text-align: center !important;
  }
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  vertical-align: middle;
  border-style: none
}

svg {
  overflow: hidden;
  vertical-align: middle
}