@import "../../../../scss/variables.scss";

.intoTheMetaverse-main-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 50px 0px;
	p {
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 10px;
	}
}
.intoTheMetaverse-inner-wrapper {
	width: 80%;
	margin: auto;
}
.create {
	color: $pink;
}
.collect {
	color: $blue;
}
.collab {
	color: $green;
}
