$primary: #4C00EE;
$primary-2: #bd0dc3;
$primary-3: #f02be0;
$secondary: #02cfd9;
$secondary-2: #4493d9;
$secondary-3: #43a7f9;
$secondary-4: #43a7f9;

$white: #fff;
$black: #000;
$light: #e9e9e9;
$light-dim: #b3b3b3;
$dark: #000;

$pink: #ff00ff;
$pink-dim: #ac2aac;
$pink-pale: #fa98fa;
$blue: #2fafff;
$green: #4dfc87;

$transparent: #ffffff00;