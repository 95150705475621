.blogPostSlider-main-wrapper {
	width: 100%;
	padding: 20px 0px;
}
.blogPostSlider-inner-wrapper {
	width: 80%;
	margin: auto;
	padding-top: 60px;
	padding-bottom: 40px;
}
.blogSlider-wrapper {
	width: 800px;
	margin: auto;
}
@media screen and (max-width: 480px) {
	.blogPostSlider-main-wrapper {
		width: 100%;
		padding: 10px 0px;
	}
	.blogPostSlider-inner-wrapper {
		width: 70%;
		margin: auto;
		padding-top: 60px;
		padding-bottom: 30px;
	}
	.blogSlider-wrapper {
		width: 150px;
		margin: auto;
	}
}
