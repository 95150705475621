@import '../../../../scss/variables.scss';

$space: 0.2em;
$border: 1px solid $green;



.badge-base {
    font-size: 50px;
    color: $white;
    line-height: 1em;
    padding: 0.35em 0.55em;
    border: $border;
    border-color: $blue;
    margin: $space 0 0 0;
    position: relative;
    &::after, &::before {
        content: '';
        position: absolute;
    }
    &::before {
        top: -$space;
        left: $space;
        width: 100%;
        height: 100%;
        border-top: $border;
        border-right: $border;
    }
    &::after {
        width: $space;
        bottom: calc($space - 1px);
        border-bottom: $border;
        right: -$space;
    }
    >span {
        position: absolute;
        top: -$space;
        height: $space;
        border-left: $border;
        left: $space;
    }
}