/* currentProjectsCard-main-wrapper */
@import "../../../../../../scss/variables.scss";

.currentProjectsCard-main-wrapper {
	border: 3px solid $pink-dim;
	border-radius: 20px;
	width: 270px;
	height: 320px;
	position: relative;
	padding: 0 10px;
	transition: all 0.4s;
	transition-property: transform, border;
	transform: scale(1);
	cursor: pointer;

	&.coming-soon {
		cursor: default;
	}

	&:not(.coming-soon):hover {
		transform: scale(1.02);
		border: 3px solid $pink;
		img {
			border: 3px solid $pink;
		}
	}
	img {
		width: 200px;
		border-radius: 50%;
		top: -170px;
		left: 35px;
		background: #000;
		position: absolute;
		border: 3px solid $transparent;
		transition: border 0.4s ease;
	}
	.title {
		margin-top: 40px;
		font-size: 16px;
		text-align: center;
		font-weight: 600;
		color: $pink-pale;
	}
	p {
		font-size: 13px;
		line-height: 18px;
	}
	b {
		color: $white;
	}

	@keyframes flickerAnimation {
		0%   { opacity:1; }
		50%  { opacity:0; }
		100% { opacity:1; }
	}
	@-o-keyframes flickerAnimation{
		0%   { opacity:1; }
		50%  { opacity:0; }
		100% { opacity:1; }
	}
	@-moz-keyframes flickerAnimation{
		0%   { opacity:1; }
		50%  { opacity:0; }
		100% { opacity:1; }
	}
	@-webkit-keyframes flickerAnimation{
		0%   { opacity:1; }
		50%  { opacity:0; }
		100% { opacity:1; }
	}

	.coming-soon-text {
		font-size: 14px;
		font-weight: 600;
		color: $pink;
		text-align: center;
		-webkit-animation: flickerAnimation 3s infinite ease;
   -moz-animation: flickerAnimation 3s infinite ease;
   -o-animation: flickerAnimation 3s infinite ease;
    animation: flickerAnimation 3s infinite ease;
	}
}
@media only screen and (max-width: 600px) {
	.currentProjectsCard-main-wrapper {
		margin: auto;
		margin-bottom: 250px;
	}
}
