@import "../../../../../../scss/variables.scss";

.lounge-swiper {
	.swiper {
		width: 100%;
	}
	.mobile-view {
		display: none;
	}
	.swiper-slide {
		.banner-container {
			padding: 14px;
			width: 100%;
			object-fit: cover;
			cursor: pointer;
			transform: scale(1);
			transition: transform 0.2s ease;

			&:hover {
				transform: scale(1.02);
			}
		}
		img {
			width: 100%;
			object-fit: cover;
			border: 2px solid $pink;
			border-radius: 20px;
		}
	}
	.mySwiper {
		padding: 30px 0px;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
	}
	@media screen and (max-width: 480px) {
		.Desktop-view {
			display: none;
		}
		.mobile-view {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 5px;
		}
		.swiper {
			width: 400px !important;
			height: 150px !important;
		}
		.swiper-slide {
			img {
				width: 500px !important;
				height: 150px !important;
				object-fit: cover;
				border: 2px solid $pink;
				border-radius: 20px;
			}
		}
		.mySwiper {
			padding: 20px 0px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
		}
	}
}
