@import "../../../../scss/variables.scss";

/* community-main-wrapper */
.community-main-wrapper {
	width: 100%;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.community-inner-wrapper {
	width: 85%;
	border-top: 1px solid gray;
	border-bottom: 1px solid gray;
	h1 {
		font-size: 24px;
		font-weight: 600;
		border-left: 2px solid $green;
		padding-left: 15px;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	p {
		padding-left: 30px;
		span {
			color: $pink;
			font-style: italic;
		}
	}
}
.community-icons-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 40px;
	.pink-line {
		height:2px;
		width: 22px;
		background-color: $pink;
		margin-right: 10px;
	}
	.text {
		color: $white;
		font-weight: 600;
		font-size: 16px;
		margin-right: 14px;
	}
	img {
		width: 42px;
		margin-left: 15px;
		cursor: pointer;
	}
}
.community-icons {
	display: flex;
	a {
		transform: translateY(0);
		transition: all 0.4s;
		&:hover {
			transform: translateY(-3px);
		}
	}
}
@media only screen and (max-width: 600px) {
	.community-icons-wrapper {
		display: block;
		align-items: center;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 40px;
	}
	.community-icons {
		margin-top: 20px;
	}
}
