.error-msg {
  color: rgb(212, 31, 31);
  font-size: 18px;
}

.success-msg {
  color: rgb(36, 204, 36);
  font-size: 18px;
}

.approved-title {
  color: rgb(216, 216, 216);
  font-size: 18px;
  font-weight: 600;
}

.approved-item {
  color: rgb(216, 216, 216);
  font-size: 12px;
}