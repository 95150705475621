@import "../../../../scss/variables.scss";

/* ixLabs-main-wrapper */
.ixLabs-main-wrapper {
	width: 100%;
}
.ixLabs-inner-wrapper {
	width: 80%;
	display: flex;
	flex-direction: column;
	margin: auto;
}
.ixLabs-heading {
	color: #fff;
	border-left: 2px solid $pink;
	padding-left: 10px;
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 15px;
}
.ixLabs-text {
	margin-bottom: 40px;
	padding-left: 30px;
	font-size: 15px;
}
.ixLabs-image-container {
	display: flex;
}
.ixLabs-leftSide {
	width: 50%;
	img {
		width: 200px;
		border: 2px solid $pink;
		border-radius: 30px;
	}
}
.image-row1 {
	display: flex;
	gap: 40px;
	margin-bottom: 40px;
	padding-left: 50px;
}
.image-row2 {
	display: flex;
	gap: 40px;
	margin-bottom: 40px;
	padding-left: 50px;
}
.ixLabs-rightSide {
	width: 50%;
	color: #fff;
	padding-top: 120px;
	h1 {
		color: #fff;
		font-size: 22px;
		font-weight: 600;
		padding-left: 30px;
		margin-bottom: 20px;
		span {
			color: #fff;
			font-size: 20px;
			font-weight: 300;
		}
	}
}
.ixLabs-bottomText {
	color: gray;
	font-style: italic;
	margin: auto;
	font-size: 14px;
	margin-bottom: 50px;
}
@media only screen and (max-width: 600px) {
	.ixLabs-image-container {
		display: block;
	}
	.ixLabs-rightSide {
		width: 100%;
		color: #fff;
		padding-top: 20px;
	}
}
