.f-normal {
    font-size: 1rem;
}
.h6, h6 {
    font-size: 1.222rem;
}
.small {
    font-size: .778rem;
}
h1, .h1 {
    font-size: 3.25rem;
}
h4, .h4 {
    font-size: 1.5rem;
}
h5, .h5 {
    font-size: 1.4rem;
}
h2, .h2 {
    font-size: 2.5rem;
}
h3, .h3 {
    font-size: 1.75rem;
}