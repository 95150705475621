@import "../../../../../../scss/variables.scss";

.belowSwiper {
	width: 100%;
	padding: 20px;
	margin-bottom: 50px;
}
.mobile-viewSlider {
	display: none;
}
.below-swiper-slide {
	display: flex;
	flex-direction: column;
	color: white;
	text-align: center;

	.baller-wrapper {
		width: 100%;
		height: fit-content;
		transition: transform 0.4s ease;
		transform: translateY(0);
		cursor: pointer;

		&.next {
			cursor: default;
		}

		&:hover {
			transform: translateY(-8px);
		}
		a {
			color: #fff;
			text-decoration: none;
		}
	}

	img {
		margin: auto;
		text-align: center;
		border: none;
		border-radius: 50%;
		width: 70px !important;
		height: 70px !important;
		cursor: pointer;
		&:hover {
			border: none;
			border-radius: 50%;
			cursor: pointer;
		}
	}
	h5 {
		margin-top: 10px;
		font-size: 14px;
		font-weight: 600;
	}
	.topshot-name {
		font-size: 13px;
		font-weight: 400;
	}
	p {
		font-size: 12px;
		font-weight: 300;
		span {
			color: $pink;
			font-weight: bold;
			font-size: 12px;

			&.blue {
				color: $blue;
			}
		}
	}
}
.tickWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}
.tickIcon {
	color: white;
	font-weight: 300;
	background-color: $pink;
	border-radius: 50%;
	padding: 1px;
	font-size: 12px;
}
@media screen and (max-width: 480px) {
	.desktop-viewSlider {
		display: none;
	}
	.mobile-viewSlider {
		display: flex;
	}
	.belowSwiper {
		width: 100%;
		padding: 10px;
	}
	.below-swiper-slide {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		img {
			border: none;
			border-radius: 50%;
			width: 70px !important;
			height: 70px !important;
			cursor: pointer;
			&:hover {
				border: none;
				border-radius: 50%;
				cursor: pointer;
			}
		}
		h5 {
			margin-top: 10px;
			font-size: 14px;
			font-weight: 600;
		}
		h6 {
			font-size: 13px;
			font-weight: 300;
		}
		p {
			font-size: 12px;
			font-weight: 300;
			span {
				color: $pink;
				font-weight: bold;
				font-size: 12px;
			}
		}
	}
	.tickWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.tickIcon {
		color: white;
		font-weight: 300;
		background-color: $pink;
		border-radius: 50%;
		padding: 1px;
		font-size: 12px;
	}
	.lounge-subtitle {
		font-size: 22px;
		font-weight: 400;
		color: #fff;
		margin-bottom: 40px;
	}
}
