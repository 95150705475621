.salesCard-main-wrapper {
	color: #fff;
}
.salesCard-inner-wrapper {
	width: 240px;
	border: 2px solid gray;
	border-radius: 20px;
	border-radius: 20px;
}
.ImgWrapper {
	width: 100%;
	background-color: rgb(0, 0, 0);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	img {
		color: gray;
		width: 100%;
		max-width: 100%;
		padding: 16px;
		margin: auto;
	}
}
.salesCard-content {
	background-color: rgb(49, 49, 71);
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	height: 130px;
	div {
		.sale-text {
			color: #fff;
			font-size: 10px;
			font-weight: 300;
			margin: 11px;
			p {
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}
.text {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.cardText {
	font-size: 13px;
	font-weight: 600;
	margin-left: 10px;
}

.sale-text {
	&.price {
		text-align: right;
	}
	color: #fff;
}

@media screen and (max-width: 480px) {
	.salesCard-inner-wrapper {
		width: 200px;
		border: 1px solid gray;
		border-radius: 20px;
		margin: auto;
		border-radius: 20px;
	}
	.ImgWrapper {
		width: 100%;
		height: 180px;
		background-color: rgb(20, 20, 28);
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		img {
			color: gray;
			width: 120px;
			padding-top: 28px;
			margin: auto;
		}
	}
	.salesCard-content {
		background-color: rgb(49, 49, 71);
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		height: 130px;
		div {
			h5 {
				color: rgb(77, 103, 130);
				font-size: 10px;
				font-weight: 300;
				margin: 11px;
				p {
					font-size: 14px;
					font-weight: 600;
				}
			}
		}
	}
	.text {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.cardText {
		font-size: 13px;
		font-weight: 600;
		margin-left: 10px;
	}
}
