/* lounge-main-wrapper */
.lounge-main-wrapper {
    margin:auto;
    max-width: 1000px;
    overflow-x:hidden;
}

.lounge-subtitle {
	font-size: 24px;
	font-weight: 600;
	color: #fff;
    text-align: left;
	margin-bottom: 20px;
}
.cat-bg-img {
    display: block;
    position: absolute;
	width: 100%;
 	max-width: 1000px;
	z-index: -1;
    overflow-x:hidden;
    overflow: hidden;

    &.upper {
        top: 30%;
        left: -400px;
    }

    &.middle {
        top: 100%;
        right: -300px;
    }

    &.lower {
        width: 60%;
 	    max-width: 600px;
        top: 164%;
        left: -260px;
    }

	@media only screen and (max-width: 991px) {
	 max-width: 600px;
	}

}