// @import "../../node_modules/bootstrap/scss/bootstrap";
@import './variables.scss', './mixins.scss','./colorsstyles.scss', './buttons.scss',
 './opacity.scss', './z-index.scss','./bootstrap.scss','./helpers.scss',
'./border.scss', './f-sizes.scss', './container.scss', './radius.scss', './fonts.scss';


:root {
  --app-height: 100vh;
}

html {
  font-size: 16px;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
	padding: 0;
  	margin: 0;
	-webkit-tap-highlight-color: transparent;
  	-webkit-touch-callout: none;
	background: #000;
	font-family: 'Montserrat', sans-serif;
	color: rgb(233, 233, 233);

	//overflow-y: scroll;
	//overflow-x: hidden;

	&.bg-multi-cat {
		background: url('../assets/images/backgrounds/multi-cat-bg.png') $dark;
	}

	&.overflow-x-hidden {
		overflow-x: hidden;
	}

	&.overflow-y-hidden {
		overflow-y: hidden;
	}
}

.transition {
  transition: linear 0.3s all;
}

@media (max-width: 370px) {
  html,
  body {
    font-size: 14px;
  }
}

.wallet-connect-btn {
	display: inline-block;
	justify-content: center;
	align-items: center;
	align-content: center;
	font-family: "Roboto", sans-serif;
	text-transform: capitalize;
	z-index: 1;
	font-weight: 700;
	padding: 0 15px;
	border: 0;
	overflow: hidden;
	color: #fff;
	background-color: #000000;
	height: 70px;
	font-size: 24px;
	border-radius: 5px;
	text-align: center;
	line-height: 70px;
	max-height: 70px;
	padding-left: 20px;
	padding-right: 20px;
	min-width: 320px;
	width: fit-content;
	position: relative;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	-webkit-transition: background-color 0.3s linear;
	-moz-transition: background-color 0.3s linear;
	-ms-transition: background-color 0.3s linear;
	-o-transition: background-color 0.3s linear;
	transition: background-color 0.3s linear;
	&:hover {
		background-color: #0070ec !important;
	}
}
.btn-img {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	transform: translateY(-3px);
	margin-left: 7px;
}
.abs-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.loading-img {
	display: inline-block;
	vertical-align: middle;
	width: 26px;
	max-width: 26px;
}
.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.d-inline {
	display: inline;
}
.d-flex {
	display: flex;
}
ul {
	&::-webkit-scrollbar-thumb {
		background-color: #353948;
		border: 4px solid transparent;
		border-radius: 8px;
		background-clip: padding-box;
	}
	&::-webkit-scrollbar {
		width: 13px;
		height: 13px;
	}
}


.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

form:valid,
form:not(.submitted) {
  .form-err {
    display: none;
  }
}

.page-hero-heading {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  .title {
    text-align: center;
    font-weight: 800;
    font-size: 44px;
    padding-top: 90px;
    padding-bottom: 0px;
    font-family: 'Akira Expanded';
    font-weight: bold;
    font-style: normal;
    color: #fff;
  }
  
  .underline {
    width: 95%;
    border-bottom: 2px solid $pink;
    text-align: center;
    margin: auto;
  }
}

.page-heading {
	&.center {
		.title {
			color: #fff;
			padding-left: 15px;
			font-size: 24px;
			font-weight: 600;
			letter-spacing: 1px;
		}
	}

	&.left {
		width: fit-content;
		display: flex;
		flex-direction: column;
		.title {
			color: #fff;
			border-left: 2px solid $pink;
			padding-left: 15px;
			font-size: 24px;
			font-weight: 600;
		}
	}

	.underline {
    width: 105%;
    border-bottom: 1px solid $pink;
    text-align: center;
    margin: auto;
  }
}

// remove later
.mb-45 {
  margin-bottom: 45px !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.overflow-hidden {
	overflow: hidden;
}

a {
	&.basic {
		color: #fff;
		text-decoration: none;
	}
}

.no-underline {
	text-decoration: none;
}