@import "../../scss/variables.scss";

.nll-footer {
  	border-top: 2px solid #a1a1a19e;
  	position: relative;
    z-index: 10;
    left: 0;
    bottom: 0;
    min-height: 80px;
    width: 100%;
    background-color: #000;
    overflow-y: hidden;
  .footer-bottom {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    .flex-row{
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 991px) {
       display: block;
       text-align: center;
      }
    }

    .footer-rights {
      color: #bdbdbd;
      text-align: right;
      width: 200px;
      p{
        font-size: 12px;
        margin-top: 13px;
      }
      a{
        display: inline-block;
        color: #bdbdbd;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover{
          color: $pink;
        }
      }
      @media only screen and (max-width: 991px) {
        width: 100%;
        text-align: center;

        &.dummy {
          display: none;
        }
      }
    }

    .footer-logo {
      @media only screen and (max-width: 991px) {
      }
      text-align: center;
      display: block;
      
      img{
        margin-top: 10px;
        margin-bottom: 10px;
        height:40px;
        @media only screen and (max-width: 767px) {
         height: 40px;
        }
      }
    }

    .footer-list {
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          display: inline-block;
          &:not(:last-child) {
          margin-right: 6px;
          }
          a{
            display: inline-block;
            transition: all .2s ease-in;
            &:hover{
              opacity: .7;
            }
            img{
              height: 23px;
            }
          }
        }
      }
    }
  }
}