@import "../../scss/variables.scss";
/* faqs-main-wrapper */
/* faq-heading */
/* faq-list */
.faqs-main-wrapper {
	margin-bottom: 80px;
	margin-top: 46px;
}
.faq-heading {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	width: 60%;
	margin: auto;
	position: relative;
	.faq-caret {
		font-size: 34px;
		color: $pink;
		position: absolute;
		right: 10px;
		cursor: pointer;
	}
	h1 {
		font-size: 26px;
    font-weight: 800;
		text-transform: uppercase;
		color: #fff;
		border-bottom: 2px solid $pink;
		padding-inline: 30px;
		text-align: center;
	}
}
.faq-list {
	width: 60%;
	margin: auto;
	margin-top: 30px;
	.faq-item {
		border-bottom: 1px solid rgb(54, 53, 53);
		margin-bottom: 15px;
		.question {
			display: flex;
			justify-content: space-between;
			font-weight: 700;
			font-size: 18px;
			color: #fff;
			padding-right: 10px;
			margin-bottom: 15px;
			cursor: pointer;
			text-transform: uppercase;
			i {
				font-size: 24px;
			}
		}
		p {
			color: #fff;
			margin-bottom: 15px;
			transition: all 0.5s ease 0s;
			-webkit-transition: all 0.5s ease 0s;
			-moz-transition: all 0.5s ease 0s;
			-ms-transition: all 0.5s ease 0s;
			-o-transition: all 0.5s ease 0s;
		}
		a {
			color: $pink-dim;
			text-decoration: none;
			font-weight: 600;
			&:hover {
				color: $pink;
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.faq-list {
		width: 80% !important;
		margin: auto;
		margin-top: 30px;
	}
}
