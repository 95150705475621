.CurrentCouncilBody-main-wrapper {
	background-color: black;
	width: 100%;
}
.CurrentCouncilBody-inner-wrapper {
	width: 80%;
	margin: auto;
}
.CurrentCouncilBody-heading {
	color: #fff;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight: 500;
	font-style: normal;
	margin-bottom: 8px;
}
.CurrentCouncilBody-text {
	font-size: 15px;
	color: rgb(218, 214, 214);
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cardRow1 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 40px;
}
.cardRow2 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-top: 40px;
	padding-bottom: 10px;
}
@media only screen and (max-width: 600px) {
	.cardRow1 {
		display: block;
	}
	.cardRow2 {
		display: block;
	}
}
