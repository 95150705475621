/*--------------------------------------------------------------
# landing page
--------------------------------------------------------------*/

@import "../../scss/variables.scss";

.landing {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden; /* Hide scrollbars */
    overflow-y: auto; /* Hide scrollbars */
  
    .hero-texts {
      padding-top: 20%;
  
      @media only screen and (max-width: 991px) {
        padding-top: 100px;
      }
      @media only screen and (max-width: 767px) {
        padding-top: 20px;
      }
    }
  
    .enter-btn{
      position: relative;
      z-index: 10;
      text-align: center;
    }
  
    .texture-btn{
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      max-width: 407px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      background-image: url('../../assets/images/landing/btn.png');
      background-repeat: no-repeat;
      background-size: cover;
      border-radius:25px;
      font-weight: 700;
      color: $white;
      font-size: 18px;
      text-transform: uppercase;
      box-shadow: 0 0 0 0.25rem rgba($pink, 0);
      transition: all .4s ease-in;
      text-align: center;
      cursor: pointer;
  
      &:hover , &:focus{
        opacity: 1;
       box-shadow: 0 0 0 0.25rem rgba($pink, 0.5);
      }
      @media only screen and (max-width: 991px) {
        // margin-left: auto;
      }
    }
  
    .shape-3{
      position: absolute;
          opacity: 0.2;
          z-index: 1;
  
      width: 1200px;
      height: 1200px;
      bottom: -100px;
      right: -350px;
      // z-index: -1;
      @media only screen and (max-width: 767px) {
        width: 1000px;
        height: 1000px;
        bottom: -100px;
        right: -300px;
      }
    }
  }
  
  .shape-landing{
    display: block;
    width: 100%;
   max-width: 1000px;
  
    position: absolute;
    bottom: -429px;
    right: -307px;
    z-index: -1;
    @media only screen and (max-width: 991px) {
     max-width: 600px;
    }
  }
  
  .social-list{
    position: absolute;
    top: 0;
    left: 82%;
    @media only screen and (max-width: 767px) {
    //  right: -44px;
    }
    @media only screen and (max-width: 606px) {
    //  right: 10px;
     top: 12px;
    }
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        &:not(:last-child) {
        margin-bottom: 8px;
        @media only screen and (max-width: 606px) {
        margin-bottom: 4px;
         }
        }
        a{
          display: inline-block;
          transition: all .2s ease-in;
          img{
            height: 35px;
            @media only screen and (max-width: 767px) {
             height: 30px;
            }
            @media only screen and (max-width: 606px) {
             height: 24px;
             }
          }
          &:hover{
            transform: translateY(-3px);
            box-shadow: 0 3px 20px 0px rgba($pink, 0.92);
            border-radius: 50%;
          }
        }
      }
    }
  }
  
  /*--------------------------------------------------------------
  # main-wrapper"
  --------------------------------------------------------------*/
  
  .main-wrapper {
      padding: 80px 0px 0px 0px;
      .main-bg {
          position: relative;
      width: 80%;
      margin-left:auto;
      margin-right: auto;
      .logo{
        height: 90px;
        position: absolute;
        top: -40px;
        left: 40%;
        transform: translateX(-40%);
      }
          .bg-full {
              width: 80%;
              height: auto;
              border-radius: 20px;
              border: 1px solid $pink;
              padding: 3px;
          }
          &::before {
              content: "";
              position: absolute;
              width: 82.1%;
              height: 100%;
              border: 1px solid $pink;
              border-radius: 20px;
              margin-left: -26px;
              top: 20px;
              z-index: -1;
        background-color: $black;
        @media only screen and (max-width: 482px) {
         width: 86.7%;
        }
          }
          &::after {
        content: "";
              position: absolute;
              width: 84.1%;
              height: 100%;
              border: 1px solid $pink;
              border-radius: 20px;
              margin-left: -52px;
              top: 46px;
        left: 0;
              z-index: -2;
        background-color: $black;
        @media only screen and (max-width: 482px) {
          width: 94.0%;
         }
          }
      }
  }
  
  
  /*--------------------------------------------------------------
  # about-section
  --------------------------------------------------------------*/
  
  .about-section{
    position: relative;
    padding-bottom: 70px;
    @media only screen and (max-width: 767px) {
     padding-bottom: 50px;
    }
  
    .about-text{
      position: relative;
      margin-top: -13px;
      margin-left: 25%;

      @media only screen and (max-width: 991px) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
       }

      h1{
        img{
          height: 135px;
          margin-bottom: 20px;
          @media only screen and (max-width: 991px) {
           height: 115px;
          }
          @media only screen and (max-width: 767px) {
           height: 100px;
          }
        }
      }
    }
  
    .inner-text{
      transform: translateY(2px);

      @media only screen and (max-width: 991px) {
        // margin-left: auto;
        text-align: center;
      }
  
      p{
        font-style: italic;
        color: lighten($color: #707070, $amount: 30%);
        font-size: 17px;
      }
    }
  }