.border {
    border: 1px solid $light;
}
.border-top {
    border-top: 1px solid $light;
}
.border-bottom {
    border-bottom: 1px solid $light;
}
.border-right {
    border-right: 1px solid $light;
}
.border-left {
    border-left: 1px solid $light;
}