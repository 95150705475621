.theLounge-main-wrapper {
	width: 100%;
	padding: 40px 0px;
	border-bottom: 1px solid gray;
}
.theLounge-inner-wrapper {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: auto;
	margin-bottom: 80px;
	text-align: center;
	p {
		font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
		font-style: italic;
	}
}
@media screen and (max-width: 480px) {
	.theLounge-inner-wrapper {
		width: 90%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: auto;
		margin-bottom: 30px;
		p {
			text-align: center;
			margin-top: 10px;
			font-size: 12px;
		}
	}
}
