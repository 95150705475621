.blur{   
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    transition: linear 0.3s all;
}

.opacity-7{
    opacity: 0.7;
}
.opacity-1{
    opacity: 0.1;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-6{
    opacity: 0.6;
}