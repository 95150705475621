.CouncilAtLarge-main-wrapper {
	background-color: black;
	width: 100%;
}
.CouncilAtLarge-inner-wrapper {
	width: 80%;
	margin: auto;
}
.CouncilAtLarge-Heading {
	color: #fff;
	display: flex;
	font-size: 22px;
	border-left: 2px solid rgb(228, 1, 228);
	padding-left: 10px;
	padding-top: 3px;
	font-weight: 800;
	text-transform: uppercase;
	font-family: 'Akira Expanded';
	font-weight: bold;
	font-style: normal;
}
.CouncilAtLarge-paragraph {
	margin-top: 30px;
	padding-bottom: 50px;
}
