@import "../../scss/variables.scss";

/* header-main-wrapper */
/* inner-wrapper */
/* logo-wrapper */
/* middle-icon-wrapper */
/* right-links-wrapper */
/* menu bars start */
/* menu bars end */
.header-main-wrapper {
	color: #fff;
	padding: 10px;
	border-bottom: 1px solid #ccc;
	position: relative;
}
.inner-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin: auto;
}
.logo-wrapper {
	display: flex;
	align-items: center;
	i {
		font-size: 24px;
	}
	img {
		width: 120px;
		margin-left: 40px;
	}
}
.middle-icon-wrapper {
	display: flex;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	img {
		width: 50px;
	}
}
.right-links-wrapper {
	display: flex;
	align-items: center;
	.links-w {
		display: flex;
		align-items: center;
		a {
			margin-left: 20px;
		}
		img {
			width: 20px;
			cursor: pointer;
		}
	}
	.connect-wallet-btn {
		background: $pink;
		margin-left: 30px;
		padding: 7px 15px;
		border-radius: 30px;
		cursor: pointer;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		-ms-border-radius: 30px;
		-o-border-radius: 30px;
	}
}
.menu {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	padding: 0;
}
.line {
	fill: none;
	stroke: #fff;
	stroke-width: 6;
	transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
	stroke-dasharray: 60 207;
	stroke-width: 6;
}
.line2 {
	stroke-dasharray: 60 60;
	stroke-width: 6;
}
.line3 {
	stroke-dasharray: 60 207;
	stroke-width: 6;
}
.opened {
	.line1 {
		stroke-dasharray: 90 207;
		stroke-dashoffset: -134;
		stroke-width: 6;
		stroke: $pink;
	}
	.line2 {
		stroke-dasharray: 1 60;
		stroke-dashoffset: -30;
		stroke-width: 6;
		stroke: $pink;
	}
	.line3 {
		stroke-dasharray: 90 207;
		stroke-dashoffset: -134;
		stroke-width: 6;
		stroke: $pink;
	}
}
@media only screen and (max-width: 600px) {
	.logo-wrapper {
		img {
			display: none;
		}
	}
	.right-links-wrapper {
		.links-w {
			display: none;
		}
		.connect-wallet-btn {
			margin-left: 0px;
		}
	}
}
