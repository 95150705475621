@import "../../scss/variables.scss";

/*--------------------------------------------------------------
# portal page
--------------------------------------------------------------*/
$bg-white:#FFFFFF;
$bg-black:#000000;
$bg-gray:#707070;
$bg-nav-collapse:#191a1a;
$primary-color:#FF00FF;
$primary-color-tp:#ff00ff00;
$text-color: #fff;
.portal {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

    .btn-container {
    z-index: 20;
    margin-left: 0;
    max-width: 320px;
    width: 320px;
    position: absolute;
    left: 76px;
    top: 35%;

    @media only screen and (max-width: 1250px) {
      left:44px;
     }

     @media only screen and (max-width: 991px) {
      left:44px;
     }

     @media only screen and (min-width: 1500px) {
      left:7%;
     }
  }

  .portal-btn {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 50%;
    max-width: 407px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    margin-top: 28px;
    transform: translateY(-14px);
    // background-image: url('../../assets/images/landing/btn.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: $pink-dim;
    border: 2px solid $pink;
    border-radius:25px;
    font-weight: 700;
    color: $white;
    font-size: 18px;
    opacity: 0;
    text-transform: uppercase;
    box-shadow: 0 0 0 0.25rem rgba($pink, 0);
    transition: background-color .4s ease-in;
    transition: opacity .2s;
    text-align: center;
    cursor: pointer;
  
    &:hover , &:focus{
    //  box-shadow: 0 0 0 0.25rem rgba($pink, 0.5);
      background-color: $pink;
    }

    &.visible {
      opacity: 1;
      transition: opacity 1.5s;
    }
    
    &.coming-soon {
      color: $pink;
      background-color: $transparent;
      border: 2px solid $transparent;
      cursor: default;
    }

    @media only screen and (max-width: 991px) {
      // margin-left: auto;
    }
  }

  #portalLoop {
    position: relative;
    z-index: -2;
  }
  
  .fs-img-base {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    justify-content: center;

    .fs-img {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
      max-height: 100%;
      display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-repeat: no-repeat;
      z-index: 1;
      &.art {
        background-image: none;
      }
      &.portal {
        background-image: url("../../assets/images/portal/portal.png");
      }
      &.anim {
        background-image: url("../../assets/images/portal/portal-switch-anim.gif");
      }
      &.active {
        background-image: url("../../assets/images/portal/portal-active.png");
      }
      &.active-1 {
        background-image: url("../../assets/images/portal/portal-active-1.png");
      }
      &.active-2 {
        background-image: url("../../assets/images/portal/portal-active-2.png");
      }
      &.active-3 {
        background-image: url("../../assets/images/portal/portal-active-3.png");
      }
      &.active-4 {
        background-image: url("../../assets/images/portal/portal-active-4.png");
      }
      &.active-5 {
        background-image: url("../../assets/images/portal/portal-active-5.png");
      }
      &.light-1 {
        background-image: url("../../assets/images/portal/portal-light-1.png");
      }
      &.light-2 {
        background-image: url("../../assets/images/portal/portal-light-2.png");
      }
      &.light-3 {
        background-image: url("../../assets/images/portal/portal-light-3.png");
      }
      &.light-4 {
        background-image: url("../../assets/images/portal/portal-light-4.png");
      }
      &.light-5 {
        background-image: url("../../assets/images/portal/portal-light-5.png");
      }
      &.art-lounge {
        background-image: url("../../assets/images/portal/art/the-lounge.png");
      }
      &.art-council {
        background-image: url("../../assets/images/portal/art/nll-council.png");
      }
      &.art-lounge-store {
        background-image: url("../../assets/images/portal/art/lounge-store.png");
      }
      &.art-scratching-post {
        background-image: url("../../assets/images/portal/art/the-scratching-post.png");
      }
      &.art-cat-cave {
        background-image: url("../../assets/images/portal/art/the-cat-cave.png");
      }
      &.art-metaverse {
        background-image: url("../../assets/images/portal/art/metaverse-hq.png");
      }
      &.overlay {
        background-image: url("../../assets/images/portal/portal-overlay.png");
        &.fadeOut {
          opacity: 0;
          transition: opacity 0.35s;
        }
        
        &.fadeIn {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
      &.active-wrapper {
        -webkit-animation: fluctuateAnim 4s infinite;
        -moz-animation: fluctuateAnim 4s infinite;
        -o-animation: fluctuateAnim 4s infinite;
          animation: fluctuateAnim 4s infinite;
      }
      // portal animation timings
      &.light {
        &.fadeOut {
          opacity: 0;
          transition: opacity 0.25s;
        }
        
        &.fadeIn {
          opacity: 1;
          transition: opacity 0.2s;
        }
      }
      &.art {
        &.fadeOut {
          opacity: 0;
          transition: opacity 0.35s;
        }
        
        &.fadeIn {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
      &.active {
        &.fadeOut {
          opacity: 0;
          transition: opacity 0.5s;
        }
        
        &.fadeIn {
          opacity: 1;
          transition: opacity 0.3s;
        }
      }
      &.anim {
        &.visible {
          opacity: 1;
        }
        
        &.hidden {
          opacity: 0;
        }
      }
    }

  }

  .header-img {
    position: absolute;
    max-width: 360px;
    top: 25px;
    left: 25px;
  }

  .header-icons {
    position: absolute;
    z-index: 100;
    top: 25px;
    left: 540px;
    left: 34%;

    @media only screen and (max-width: 1311px) {
      left: 450px;
     }

    @media only screen and (min-width: 1810px) {
      left: 35%;
    }

    .icon {
      z-index: 101;
      margin-bottom: 20px;

      &.middle {
        transform: translateX(-20px);
      }

      &.bottom {
        transform: translateX(-40px);
      }
    }
  }

  .content-text {
    color: #bdbdbd;
    font-size: 17px;
  }

  .shape-portal{
    display: block;
    width: 100%;
   max-width: 1000px;
  
    position: absolute;
    top: 50px;
    left: -180px;
    z-index: -1;
    @media only screen and (max-width: 991px) {
     max-width: 600px;
    }
  }

  @keyframes fluctuateAnim {
    0%   { opacity:1; }
    20%  { opacity:0.9; }
    50%  { opacity:0.55; }
    100% { opacity:1; }
  }
  @-o-keyframes fluctuateAnim {
    0%   { opacity:1; }
    20%  { opacity:0.9; }
    50%  { opacity:0.55; }
    100% { opacity:1; }
  }
  @-moz-keyframes fluctuateAnim {
    0%   { opacity:1; }
    20%  { opacity:0.9; }
    50%  { opacity:0.55; }
    100% { opacity:1; }
  }
  @-webkit-keyframes fluctuateAnim {
    0%   { opacity:1; }
    20%  { opacity:0.9; }
    50%  { opacity:0.55; }
    100% { opacity:1; }
  }

  .portal-selector {
    z-index: 20;
    margin-left: 0;
    max-width: 320px;
    width: 320px;
    position: absolute;
    left: 76px;
    top: 35%;

    @media only screen and (max-width: 1250px) {
      left:44px;
     }

     @media only screen and (max-width: 991px) {
      left:44px;
     }

     @media only screen and (min-width: 1500px) {
      left:7%;
     }
    
    color: #fff;
    .top {
      &.row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .col-2.left {
        text-align: left;
        .portal-nav {
          text-align: left;
        }
      }
      .col-2.right {
        text-align: right;
        .portal-nav {
          text-align: right;
        }
      }
      .portal-nav {
        text-align: center;
        cursor: pointer;

        img {
          cursor: pointer;
          text-align: center;
          width: 13px;
          margin:auto;
          border: none;
        }

        &.col-2.swiper-next {
          padding-left: 0;
        }
        
        &.col-2.swiper-prev {
          padding-right: 0;
        }
      }
      .portal-title {
        font-size: 20px;
        font-style: italic;
        border-top: solid 2px #f0f;
        border-bottom: solid 2px #f0f;
        // padding-top: 5px;
        // padding-bottom: 5px;
        font-weight: 300;
        text-align: center;
      }
    }
    .bottom {
      padding-top: 32px;
      font-size: 14px;
      font-weight: 300;
      padding-left: 10px;

      b {
        font-weight: 600;
        font-style: italic;
      }
    }
  }
  
.portal-footer {
  border-top: 2px solid #a1a1a19e;
  position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    min-height: 80px;
    width: 100%;
    background-color: #000;
    overflow-y: hidden;
  .footer-bottom {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    .flex-row{
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 991px) {
       display: block;
       text-align: center;
      }
    }

    .footer-rights {
      color: #bdbdbd;
      text-align: right;
      width: 200px;
      p{
        font-size: 12px;
        margin-top: 13px;
      }
      a{
        display: inline-block;
        color: #bdbdbd;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover{
          color: $primary-color;
        }
      }
      @media only screen and (max-width: 991px) {
        width: 100%;
        text-align: center;

        &.dummy {
          display: none;
        }
      }
    }

    .footer-logo {
      @media only screen and (max-width: 991px) {
      }
      text-align: center;
      display: block;
      
      img{
        margin-top: 10px;
        margin-bottom: 10px;
        height:40px;
        @media only screen and (max-width: 767px) {
         height: 40px;
        }
      }
    }

    .footer-list {
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          display: inline-block;
          &:not(:last-child) {
          margin-right: 6px;
          }
          a{
            display: inline-block;
            transition: all .2s ease-in;
            &:hover{
              opacity: .7;
            }
            img{
              height: 23px;
            }
          }
        }
      }
    }
  }
}

}

.soc-btn {
  height: 23px;
  width: 23px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
.soc-btn.medium {
  background-image: url("../../assets/images/social/medium.png");
}
.soc-btn.medium:hover {
  background-image: url("../../assets/images/social/medium-pink.png");
}
.soc-btn.twitter {
  background-image: url("../../assets/images/social/twitter.png");
}
.soc-btn.twitter:hover {
  background-image: url("../../assets/images/social/twitter-pink.png");
}
.soc-btn.topshot {
  background-image: url("../../assets/images/social/topshot.png");
}
.soc-btn.topshot:hover {
  background-image: url("../../assets/images/social/topshot-pink.png");
}
.soc-btn.looks {
  background-image: url("../../assets/images/social/looks.png");
}
.soc-btn.looks:hover {
  background-image: url("../../assets/images/social/looks-pink.png");
}
.soc-btn.discord {
  background-image: url("../../assets/images/social/discord.png");
}
.soc-btn.discord:hover {
  background-image: url("../../assets/images/social/discord-pink.png");
}
.soc-btn.opensea {
  background-image: url("../../assets/images/social/opensea.png");
}
.soc-btn.opensea:hover {
  background-image: url("../../assets/images/social/opensea-pink.png");
}

.example {
  width: 200px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border .2s ease-in-out;
}

.example:hover {
  border-color: #337ab7;
}

.example__label {
  text-align: center;
  font: 16px/1.5 Helvetica, Arial;
}