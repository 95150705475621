.claim-header {
  font-size: 2.25rem;
}

.note {
  font-size: 12px;
  color: rgb(202, 165, 165);
  margin-top: 15px;
  margin-bottom: 10px;
}

.disconnect-btn {
  font-size: 16px;
  color: rgb(202, 165, 165);
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    color: rgb(255, 255, 255);
  }
}