.blogSwiper {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	margin: auto;
	padding: auto !important;
	border-radius: none !important;
}
.mobile-viewBlogSlider {
	display: none;
}
.blog-slide {
	width: 150px !important;
	height: 200px !important;
	border-radius: none !important;
	img {
		border: none !important;
		border-radius: 0px !important;
		width: 100% !important;
		height: 100% !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.blog-name {
	color: rgb(206, 203, 203);
	font-size: 20px;
	font-weight: 100;
	margin-bottom: 10px;
}
@media screen and (max-width: 480px) {
	.blogSwiper {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		margin: auto;
		padding: auto !important;
		border-radius: none !important;
	}
	.desktop-viewBlogSlider {
		display: none;
	}
	.mobile-viewBlogSlider {
		display: flex;
	}
	.blog-slide {
		width: 150px !important;
		height: 200px !important;
		border-radius: none !important;
		img {
			border: none !important;
			border-radius: 0px !important;
			width: 100% !important;
			height: 100% !important;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.blog-name {
		color: rgb(206, 203, 203);
		font-size: 20px;
		font-weight: 100;
		margin-bottom: 10px;
	}
}
