.AgendaBudget-main-wrapper {
	background-color: black;
	width: 100%;
	padding: 30px;
}
.AgendaBudget-inner-wrapper {
	width: 80%;
	border-top: 1px solid rgb(54, 53, 53);
	border-bottom: 1px solid rgb(54, 53, 53);
	margin: auto;
	padding: 70px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.AgendaBudget-leftSide {
	width: 50%;
}
.AgendaBudget-Heading1 {
	margin-bottom: 10px;
}
.AgendaBudget-Heading2 {
	margin-bottom: 10px;
	margin-top: 100px;
}
.AgendaBudget-Value {
	color: rgb(141, 245, 141);
	font-size: 18px;
	font-weight: 300;
}
.AgendaBudget-text1 {
	margin-top: 15px;
	margin-bottom: 5px;
	padding-left: 30px;

	&.top {
		font-style: italic;
	}
}
.AgendaBudget-text2 {
	margin-top: 15px;
	margin-bottom: 5px;
	padding-left: 30px;
}
.AgendaBudget-rightSide {
	width: 50%;
}
.AgendaBudget-NLL-Wallet {
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	font-weight: 300;
	font-size: 14px;
	letter-spacing: 0.4px;
}
.walletPlaceholder-img {
	width: 300px;
	max-width: 300px;
	margin: auto;
	img {
		border-radius: 30px;
	}
}
@media only screen and (max-width: 600px) {
	.AgendaBudget-inner-wrapper {
		flex-direction: column;
		width: 90%;
		margin: auto;
	}
	.AgendaBudget-rightSide {
		width: 100%;
	}
	.AgendaBudget-leftSide {
		width: 100%;
	}
	.walletPlaceholder-img {
		width: 300px;
		max-width: 300px;
		margin: 0;
		margin-top: 30px;
	}
}
